@white-transparency: rgba(255, 255, 255, 0.4);
@white-transparency-hover: rgba(255, 255, 255, 0.7);
@default-font-size: 18px;
@very-small-font-size: 10px;
@small-font-size: 13px;
@large-font-size: 30px;
@font-size-74: 74px;
@medium-font-size: 23px;
@extra-large-font-size: 40px;
@large-icon-font-size: 32px;
@orphy-green: #279fad;
@orphy-green-hover: #028995;
@default-transition-duration: 0.5s;
@font-weight-light: 300;
@font-weight-normal: 400;
@font-weight-bold: 700;
@default-margin-value: 15px;
@high-margin-value: 45px;
@default-padding-value: 30px;
@title-color: #444557;
@red-color: #ff5556;
@orange-color: #f9b100;
@green-color: #27c274;
@gray-color: #ccdbe0;
@violet-color: #8064b2;
@blue-color: #2d5ca0;
@error-color: #a94442;
@inactive-color: #959595;

@header-height: 75px;
@footer-height: 75px;

#addTwinPopup {
    z-index: 10000000;
}

#addTwinPopup .km-switch-wrapper {
    background: #ccdfe6 !important;
}

#addTwinPopup k-switch km-switch k-widget km-widget k-switch-off km-switch-on > .km-switch-handle {
    background: #2d9fad !important;
}

#twinPersonView .km-switch-off .k-switch-handle {
    background: white !important;
}

.tabStrip {
    background-color: transparent;
    border: none;
}

#twinLessonTabstrip.tabStrip ul > li {
    background-color: transparent;
    border: none;
    border-width: unset;
}

#twinLessonTabstrip.tabStrip ul > li.k-state-active {
    background-color: azure;
    border: none;
    border-width: unset;
}

#twinLessonTabstrip.tabStrip ul > li > span {
    border: none;
}

#twinLessonTabstrip.tabStrip ul > li.k-state-active > span {
    border: none;
}

#twinLessonTabstrip.tabStrip ul {
    padding: 0;
}

#twinLessonTabstrip.tabStrip ul {
    padding: 0;
}

#twinLessonTabstrip.k-tabstrip .k-content.k-state-active {
    background-color: transparent;
    border: none;
    border-width: unset;
}

#twinLessonTabstrip.tabStrip ul > li {
    background-color: transparent;
    border: none;
    border-width: unset;
}

#twinLessonTabstrip.tabStrip ul > li.k-state-active {
    background-color: azure;
    border: none;
    border-width: unset;
}

#twinLessonTabstrip.tabStrip ul > li > span {
    border: none;
}

#enterLessonTabStrip.tabStrip ul > li {
    background-color: transparent;
    border: none;
    border-width: unset;
}

#enterLessonTabStrip.tabStrip ul > li.k-state-active {
    background-color: azure;
    border: none;
    border-width: unset;
}

#enterLessonTabStrip.tabStrip ul > li > span {
    border: none;
}

#enterLessonTabStrip.tabStrip ul > li.k-state-active > span {
    border: none;
}

#enterLessonTabStrip.tabStrip ul {
    padding: 0;
}

#enterLessonTabStrip.tabStrip ul {
    padding: 0;
}

#enterLessonTabStrip.k-tabstrip .k-content.k-state-active {
    background-color: transparent;
    border: none;
    border-width: unset;
}

#enterLessonTabStrip.tabStrip ul > li {
    background-color: transparent;
    border: none;
    border-width: unset;
}

#enterLessonTabStrip.tabStrip ul > li.k-state-active {
    background-color: azure;
    border: none;
    border-width: unset;
}

#enterLessonTabStrip.tabStrip ul > li > span {
    border: none;
}

#enterLessonTabStrip.k-tabstrip .k-content.k-state-active {
    background-color: transparent;
    border: none;
    border-width: unset;
}

#surface-container {
    overflow: -webkit-paged-x;
    overflow: hidden;
}

#drawingHeader > div.default-margin.high-margin > div > div.drawing-toolbar-button.drawing-stroke-button > div > span > span > span.k-select > span {
    display: none;
}

.noBeforeContent > span:before {
    content: unset;
}

#drawingHeader > div.default-margin.high-margin > div > div.drawing-toolbar-button.drawing-stroke-button > div > span > span {
    border: none;
}

// add link color
#enterLessons .linkText {
    color: #279fad;
    padding-bottom: 10px;
}

.padding-top {
    padding-top: 10px;
}

.categoryPaperworkBill {
    color: @inactive-color;
    font-size: 11px;
}

.transparentFooter {
    background: transparent;
}

#productListProductManagerScroller .tableCell-span5.tableCell:not(:last-child) {
    padding-left: 15px;
}

.orphyDriveOneHidden {
    visibility: hidden !important;
}

.hiddenPaymentWay {
    visibility: hidden;
}

.showPaymentWay {
    visibility: visible !important;
}

.orphyDriveOneHidden.mainMenuButton {
    visibility: visible !important;
    pointer-events: none;
    opacity: 0.1;
}

.orphyDriveOneNoDisplay {
    display: none !important;
}

.mediumText > .k-checkbox-label.vku-label {
    font-weight: normal;
}

#checklistViewEducationCardListView ul li.wasInPlannedLesson {
    background-color: rgba(173, 216, 230, 0.5);
}

.k-drag-clue[style] {
    display: none;
}

html {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.noPointer {
    pointer-events: none;
}

.selectedListItem {
    background-color: #f9fafb;
}

.k-state-selected {
    color: rgb(46, 46, 46) !important;
}

.invalidInput {
    border-style: solid !important;
    border-color: red !important;
}

.removeContent img,
.contentExecuteLessonInner img {
    max-width: 100%;
    height: auto;
}

.lessonCountPopupContent {
    padding-top: 5px;
    padding-bottom: 5px;
}

.lessonCountPopupContent:last-child {
    padding-top: 5px;
    padding-bottom: 0px;
}

.selectedLessonCount {
    color: lightblue;
}

.noPhoneNumber {
    pointer-events: none;
    justify-content: flex-start !important;
}

.noPhoneNumber > a {
    text-decoration: none;
    color: #000 !important;
}

/* Syncbar Dashboard */
.syncInProgressMessage {
    font-size: @default-font-size;
}

.infoBox {
    background: @white-transparency;
    padding: 20px;
}

.infoBoxTitle {
    font-size: @medium-font-size;
}

.infoBoxTitle.warning {
    color: @red-color;
}

.infoBoxTitle.note {
    font-size: 16px;
    color: @inactive-color;
}

.infoBoxTitle.advice {
    color: @orphy-green;
    font-size: 16px;
}

.infoBoxLinkIcon i {
    font-size: @large-icon-font-size;
}

.infoBoxLinkIcon {
    margin-left: auto;
}

.infoBoxTitle.note .infoBoxIcon {
    color: white;
}

.infoBoxTitle.advice .infoBoxIcon {
    color: @orphy-green;
}

.infoBoxIcon {
    margin-right: 20px;
}

.infoBoxIcon i {
    font-size: @large-icon-font-size;
}

.syncBox {
    .infoBox;
    font-weight: @font-weight-bold;
    padding: 30px 0 30px 0;
}

.default-inline-flex.syncStatusBar {
    align-items: stretch;
}

.syncStatusBar {
    padding: 0 40px 0 0;
    margin-bottom: 20px;
    font-size: 15px;
    background: @white-transparency;
    position: relative;
}

.syncStatusBarUnsentBills,
.syncStatusBarReadonly {
    background-color: @red-color;
    color: white;
}

.syncStatusBarUnsentBills .syncStatusBarSyncIcon,
.syncStatusBarReadonly .syncStatusBarSyncIcon {
    background: #c44142;
}

.syncStatusBarUnsentBills .custom-icon-cloud-missing,
.syncStatusBarReadonly .custom-icon-readonly {
    display: inline-block !important;
}

.syncInProgress.syncStatusBarUnsentBills .custom-icon-cloud-missing {
    display: none !important;
}

.syncStatusBarUnsentBills .syncStatusBarSyncIcon i,
.syncStatusBarReadonly .syncStatusBarSyncIcon i {
    color: white;
}

.syncStatusBarUnsentBills .syncStatusBarSyncDate,
.syncStatusBarReadonly .syncStatusBarSyncDate {
    color: white;
}

.syncStatusBarUnsentBills .custom-icon-sync-success,
.syncStatusBarReadonly .custom-icon-sync-success {
    display: none;
}

.syncStatusBarLastSyncValue {
    font-size: @small-font-size;
}

.syncStatusBarUnsentBills .syncAdvice,
.syncStatusBarReadonly .syncAdvice {
    color: white;
}

.syncAdvice {
    font-size: 10px;
    color: @inactive-color;
    font-style: italic;
    margin-top: 8px;
}

em {
    font-style: normal;
}

.syncStatusBarSyncDate {
    color: #888888;
    margin-left: auto;
    border-left: 1px solid white;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.default-inline-flex.syncStatusBarSyncDate {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.syncStatusBarSyncState {
    font-size: @default-font-size;
}

.syncStatusBarSyncIcon {
    padding: 15px;
    background: @white-transparency;
}

#syncStatusTextMainview {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.syncStatusBarSyncIcon i {
    font-size: 44px;
    color: @green-color;
}

.syncInProgress .custom-icon-sync-success {
    display: none;
}

.syncInProgress .icon-sync,
.animatedIcon .icon-sync {
    display: inline-block !important;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

/* Syncbar Dashboard End */
.wlan-info {
    padding-left: 15px;
}

.billPreviewMainFrame {
    width: 100%;
    background-color: lightgrey;
}

.billPreviewContent {
    position: relative;
    background-color: darkgray;
}

.billPreviewAddress {
    .billPreviewContent;
    width: 30%;
}

.billPreviewLogo {
    .billPreviewContent;
    top: 4%;
    width: 12%;
    text-align: center;
    vertical-align: middle;
}

.billTextArea {
    background: none;
    border: none;
}

.logoPlaceholder {
    width: 150px;
    height: 150px;
    background-color: lightgray;
    text-align: center;
    vertical-align: middle;
}

.settingsButton {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom-style: solid;
    border-bottom-color: white;
    border-bottom-width: 1px;
    position: relative;
}

.settingsButton:before {
    content: "\e916";
    position: absolute;
    right: 0;
    font-family: "caviar" !important;
    margin-top: 5px;
    color: @orphy-green;
    font-size: 30px;
}

.inactiveInfo {
    color: @inactive-color;
    font-size: @font-weight-normal;
}

/* Editing Checklist */
.editingChecklist .k-icon.k-i-collapse:before {
    content: "\e915";
}

.editingChecklist .k-icon {
    font-family: "caviar" !important;
    position: relative;
    background: none;
    color: @orphy-green;
    height: auto;
    padding: 5px 3px 4px 0;
    width: auto;
    display: none;
}

.editingChecklist .k-icon:before {
    width: auto;
    height: auto;
    font-size: 24px;
    display: inline;
    line-height: 1;
}

.editingChecklist .k-icon.k-i-expand:before {
    content: "\e916";
}

.editingChecklist .k-icon.k-i-expand {
    background: none;
}

.editingChecklist {
    box-sizing: border-box;
}

.editingChecklist > .k-treeview-lines > .k-item {
    border-bottom: 1px solid white;
    margin-left: 0;
}

.editingChecklist .k-treeview-lines .k-item {
    padding: 10px 0;
}

.editingChecklist .k-treeview-lines .k-item .k-group .k-item {
    border-bottom: 1px solid white;
    margin-left: 25px;
}

.editingChecklist .k-item .k-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
}

.editingChecklist .k-treeview-lines .k-item .k-group .k-item:last-child {
    border-bottom: none;
    padding-bottom: 2px;
}

.k-group .k-item .k-in {
    display: flex;
    width: 100%;
    align-items: center;
}

.k-group .k-item .k-in.k-state-selected,
.k-group .k-item .k-in.k-state-hover {
    background: transparent;
    border: none;
    box-shadow: none;
}

.k-treeview .k-in {
    padding: 0;
    margin: 0;
    border: none;
}

.k-state-focused {
    box-shadow: none;
}

.k-group .k-item .k-in.k-state-selected {
    color: @orphy-green;
}

.k-group .k-item .k-in .icon-add,
.k-group .k-item .k-in .icon-pencil {
    margin-left: auto;
}

.editingChecklist .k-treeview-lines .k-item .k-group {
    border-top: 1px solid white;
    margin-top: 7px;
}

.editingChecklist .k-group .k-item .k-top,
.editingChecklist .k-group .k-item .k-mid,
.editingChecklist .k-group .k-item .k-bot {
    display: flex;
    align-items: center;
}

.checkListSaveNewEntryButton {
    padding-left: 22px;
}

.editingChecklist button.icons,
.editingChecklist div.icons {
    background: transparent;
    border: none;
    padding: 2px 7px 2px;
    color: @orphy-green;
    font-size: 30px;
}

span[unselectable="on"] {
    background: transparent;
    border: none;
}

span.k-state-disabled .k-icon {
    display: none;
}

.k-treeview .k-item {
    padding-left: 0;
}

.editingChecklist .editingInputFieldContainer {
    margin-bottom: 9px;
    margin-top: 9px;
}

/* Editing Checklist End */
.billStatusText {
    display: inline-block;
    vertical-align: middle;
}

#billTablePaperwork .billStatusSymbol {
    margin-right: 7px;
}

#billTablePaperwork .tableCell {
    height: 63px;
}

#createBillButton {
    margin-left: auto;
}

.searchBillsView {
    padding-left: 15px;
    padding-right: 15px;
    margin: 25px auto 0 auto;
}

.searchTimeTrackingsView {
    max-width: 300px;
    margin: 25px auto 0 auto;
}

.searchNameforBill.editingInputFieldContainer {
    border: 1px solid @orphy-green;
}

.searchNameforBill i {
    font-size: 24px;
}

.searchNameforBill span {
    color: #717171;
}

.searchNameforBillTextField {
    width: 100%;
}

.searchNameforBillResults {
    max-height: 280px;
    overflow-y: scroll;
    padding-left: 13px;
    background: white;
    padding-right: 13px;
    margin-top: -2px;
    border-radius: 0 0 4px 4px;
}

.searchNameforBillResults li {
    line-height: 50px;
    height: 50px;
}

.searchNameforBillResults li a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 50px;
    width: 100%;
}

.searchNameforBillResults li:first-child {
    margin-top: 15px;
}

.searchNameforBillResults li:last-child {
    margin-bottom: 15px;
}

#searchHeaderAllStudents.searchBillsView:after {
    right: 30px;
}

.searchHeaderAllStudents.searchBillsView {
    max-width: 400px;
    width: 100%;
}

.allStudents {
    font-size: @medium-font-size;
}

#dateTitlePaperwork {
    padding-top: 10px;
}

.billStatusSymbol {
    width: 20px;
    height: 20px;
    margin: 0 7px 0 3px;
    border-radius: 10px;
    color: white;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
}

.tableCell .btn .billStatusSymbol,
.tableCell .btn .billStatusSymbol .icons {
    font-size: 16px;
    padding: 0;
}

.tableCell .btn .billStatusSymbol .icons {
    display: block;
    line-height: 20px;
}

.billStatusSymbolPaperworkBills {
    .billStatusSymbol;
    margin-left: 15px;
}

.billStatusSymbol i {
    padding: 2px;
}

.billStatusOverdue {
    .billStatusSymbol;
    background-color: #fc5256;
}

.billStatusNotCarried i,
.billStatusNotCarried {
    .billStatusSymbol;
    background-color: #f4f4f4;
}

.billStatusPaid i {
    .billStatusSymbol;
    background-color: @green-color;
}

.billStatusCanceled i {
    .billStatusSymbol;
    background-color: @gray-color;
    margin: 0;
}

.billStatusOpen i {
    .billStatusSymbol;
    background-color: @orange-color;
}

.billStatusWaiting {
    background: #666666;
}

.billsPaperworkID {
    color: @orphy-green;
}

#billListEndlessListView .tableCell {
    padding: 10px 10px 10px 0;
}

.billTablePaperworkRow .tableCell {
    padding-right: 10px;
}

.noTransation {
    transition: none !important;
}

@font-face {
    font-family: Open Sans;
    font-weight: 400;
    src: url(fonts/OpenSans-Regular.ttf);
}

@font-face {
    font-family: Open Sans;
    font-weight: 300;
    src: url(fonts/OpenSans-Light.ttf);
}

@font-face {
    font-family: Open Sans;
    font-weight: 700;
    src: url(fonts/OpenSans-Bold.ttf);
}

.km-root .km-flat.km-pane,
.km-root .km-flat > .km-view,
.km-root .km-flat .km-slider,
.km-root .km-flat .km-switch,
.km-root .km-flat .km-shim .k-animation-container {
    .main-bg;
    font-family: Open Sans;
    font-weight: 400;
    font-size: @default-font-size;
    color: black;
}

.km-flat .km-group-title {
    border-top: none;
    border-bottom: none;
    background: transparent;
    text-indent: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    /*background: rgba(224,231,237, 1);*/
    background: @white-transparency;
    font-weight: normal;
    padding-left: 30px;
    padding-right: 30px;
}

.km-flat .km-scroll-header .km-group-title {
    background: #e0e7ed;
}

table.k-editor {
    border: none;
    border-spacing: 0;
    background: transparent;
}

table.k-editor tr[role="presentation"] {
    display: none;
}

table.k-editor .k-content {
    background: transparent;
}

.k-editor-inline {
    padding: 5px 0 5px 0;
    font-size: 25px;
    min-height: 70px;
    max-height: 200px;
}

.k-editor-inline.k-state-active,
.k-editor-inline.k-state-hover {
    background: transparent;
}

.k-editor-inline p {
    margin-top: 0;
    margin-bottom: 7px;
}

.k-editor .k-editable-area {
    border: none;
    background: transparent;
}

.k-chart,
.k-stockchart {
    height: 300px;
}

.km-content h1 {
    margin-left: 0;
    margin-right: 0;
}

.km-root a,
.km-root a:link,
.km-root a:visited {
    color: @orphy-green;
    display: inline-block;
}

input,
textarea,
keygen,
select,
button {
    font-size: 18px;
    font-family: inherit;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#login {
    margin: 10px 75px 10px 75px;
    box-sizing: border-box;
    padding: 20px 0 20px 0;
}

#login .btn {
    font-size: @medium-font-size;
    height: auto;
    padding: 15px 45px;
}

.loginLogo {
    margin-bottom: 40px;
}

/* Icon Bibliothek Custom*/
.textareaNoBorder {
    border: none;
}

div.listnoBorder.km-widget.km-listview.km-list > li {
    border: none;
}

.iconOnly {
    background: transparent;
    height: 42px;
    min-height: 42px;
    min-width: 42px;
    width: 42px;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    text-align: center;
    text-decoration: none;
    position: relative;
    font-family: inherit;
    overflow: visible;
}

.iconBtnLarge.iconOnly i {
    font-size: 27px;
    margin: 0;
}

.iconBtnLarge.iconOnly {
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
}

.iconOnly.sleepingSmileyButton {
    padding-top: 0;
    padding-bottom: 15px;
}

.sleepingSmileyButton.iconOnly {
    height: auto;
    width: auto;
}

.sleepingSmileyButton.iconOnly i {
    font-size: 68px;
}

.iconOnly:visited,
.iconOnly:link {
    text-decoration: none;
}

.iconOnly.iconOnlyOutline,
.iconOnly.iconOnlyOutline:visited,
.iconOnly.iconOnlyOutline:link .btnDropdown.iconOnlyOutline {
    border: 1px solid @orphy-green;
    padding-left: 10px;
    border-radius: 50%;
}

.iconOnly i,
.iconOnly span {
    color: @orphy-green;
    display: flex;
    font-size: 24px;
    margin: -1px 0 0 -2px;
}

.iconOnly .icon-direction-alt {
    margin-left: 0;
    margin-top: -1px;
}

.iconOnlyOutline + .btnText {
    color: @orphy-green;
}

.iconOnlyFilled {
    background: @orphy-green;
    border-radius: 50%;
}

.iconOnlyFilled i {
    color: white;
}

.iconOnly span {
    color: @orphy-green;
}

#checklistViewEducationCardListView div.tableCell.educationPaperNoteCell.cell2 > button > i {
    color: #0196fa;
}

.iconOnly span.sms-icon {
    font-size: 11px;
    position: absolute;
    top: calc(~"50% - 6px");
    font-family: inherit;
    left: calc(~"50% - 9px");
    pointer-events: none;
}

.btn .icons {
    font-size: 28px;
    display: inline-block;
    vertical-align: middle;
}

/* Icon Bibliothek Custom End*/
/* Syncscreen */
.modalFullScreen {
    width: 100%;
    height: 100%;
}

#syncScreen .km-content,
#finalAccountScreen .km-content,
#refreshStudent .km-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .main-bg;
    font-family: Open Sans;
    font-weight: 400;
}

/* Syncscreen End */
/* Help / FAQ */
.faqRow {
    font-size: @medium-font-size;
    padding: 12px 0;
    border-bottom: 1px solid white;
}

.questionBoxHelp {
    font-size: @default-font-size;
    padding: 10px 0 10px 7px;
}

.questionBoxTitle:before {
    font-family: "caviar" !important;
    content: "\e981";
    margin-right: 7px;
    color: @orphy-green;
    margin-left: -30px;
}

.questionBoxTitle.questionBoxOpen:before {
    content: "\e9a2";
}

.questionBoxTitle {
    padding-left: 30px;
}

/* Help / FAQ End*/
/* First Time Usage */
.configDone {
    background: url("img/ConfigFinishedBG.jpg") no-repeat;
    background-size: cover;
}

#firstTimeUsageView .globalHeader:after {
    display: none;
}

#firstTimeUsageView .globalHeader {
    margin-bottom: 0;
    height: 66px;
}

.position1 .configCarNavIcon {
    left: calc(~"12.5% - 60px");
    bottom: 0;
}

.position2 .configCarNavIcon {
    left: calc(~"37.5% - 60px");
}

.position3 .configCarNavIcon {
    left: calc(~"62.5% - 60px");
}

.position4 .configCarNavIcon {
    left: calc(~"87.5% - 57px");
}

.position5 .configCarNavIcon {
    left: calc(~"100% - 90px") !important;
}

.configCarNavIcon {
    transition: all ease 1s;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
}

.firstTimeUsageConfigPage {
    margin-top: 80px;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
}

.configCarNavWrapper {
    position: fixed;
    background: white;
    height: 40px;
    left: 0;
    right: 0;
    /*z-index: 1;*/
}

.configCarNav {
    position: relative;
    width: 100%;
    height: 40px;
}

.position1 .number-1 {
    color: #6d71b0;
}

.position2 .number-2 {
    color: #3e86ae;
}

.position3 .number-3 {
    color: @orphy-green;
}

.position4 .number-4 {
    color: #27b1bb;
}

.configNavGradient {
    height: 3px;
    position: fixed;
    top: 40px;
    width: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8848fe+0,00cde2+99 */
    background: #8848fe;
    /* Old browsers */
    background: -moz-linear-gradient(left, #8848fe 0%, #00cde2 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #8848fe 0%, #00cde2 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #8848fe 0%, #00cde2 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8848fe', endColorstr='#00cde2', GradientType=1);
    /* IE6-9 */
}

.configCarNavNumbersWrapper {
    position: fixed;
    top: 105px;
    left: 0;
    right: 0;
}

.configCarNavNumber {
    font-size: @large-font-size;
    transition: all ease @default-transition-duration;
    width: 25%;
    text-align: center;
}

#firstTimeUsageView .km-content {
    height: calc(~"100% - 100px");
    padding-top: 0;
}

#viewFirstTimeUsage {
    height: 100%;
}

.viewFirstTimeUsageInner {
    height: 100%;
}

#firstTimeUsageView .configurationDoneHeader .default-inline-flex {
    justify-content: center;
}

.configurationDoneHeader i {
    color: @orphy-green;
}

#firstTimeUsageView .configurationDoneHeader {
    height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
}

.viewFirstTimeUsageInner .btn {
    font-size: @medium-font-size;
    height: auto;
    padding: 13px 25px;
}

.viewFirstTimeUsageInner .btn.skipButton {
    font-size: @default-font-size;
    padding-bottom: 45px;
    position: fixed;
    margin: 0 auto;
    bottom: 15px;
    left: 0;
    width: 100%;
}

.viewFirstTimeUsageInner button i + .btnText {
    margin-left: 10px;
    margin-bottom: 2px;
}

.viewFirstTimeUsageInner .welcome {
    font-size: 75px;
    line-height: 1;
}

.viewFirstTimeUsageInner .welcomeIcon i {
    font-size: 90px;
}

.viewFirstTimeUsageInner.verticalCentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.viewFirstTimeUsageContent {
    height: calc(~"100vh - 198px");
    padding-bottom: 10px;
}

.configDonePage {
    height: calc(~"100vh - 66px");
    padding-bottom: 0;
}

#firstTimeIntroductionGallery.k-widget {
    border: none;
}

#firstTimeIntroductionGallery .introductionPhoto {
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100vw !important;
    text-align: center;
    white-space: normal;
    box-sizing: border-box;
    padding: 0 @default-padding-value;
}

.introductionPhoto-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.introductionPhoto-img-wrapper {
    margin-bottom: 50px;
}

.introductionPhoto img {
    max-width: 100%;
}

.introductionPhoto .veryLargeTitle {
    font-size: @font-size-74;
    line-height: 1.1;
    margin-bottom: 40px;
}

#headerTitleFTI .km-pages li {
    border-radius: 50%;
    height: 17px;
    width: 17px;
}

#firstTimeIntroductionGallery .photo1 {
    background-image: url("img/firsttime-usage-introduction1.jpg");
}

#firstTimeIntroductionGallery .photo2 {
    background-image: url("img/firsttime-usage-introduction2.jpg");
}

#firstTimeIntroductionGallery .photo3 {
    background-image: url("img/firsttime-usage-introduction3.jpg");
}

.firstTimeIntroductionNextButton {
    position: fixed;
    right: 10px;
    top: 50%;
    z-index: 1;
    width: auto;
}

.firstTimeIntroductionNextButton .iconOnly {
    width: auto;
    height: auto;
}

.firstTimeIntroductionNextButton i {
    font-size: 90px;
    width: 90px;
    height: 90px;
}

/* First Time Usage End*/
/* Window Confirmation */
.k-overlay {
    background: url("img/driveswiss_bg.jpg");
    background-size: cover;
    opacity: 1 !important;
    transition: all ease @default-transition-duration;
}

.k-window-titlebar {
    border: none;
}

.k-window-titlebar.k-header {
    background: transparent;
}

div.k-window {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
}

.dialogIcon .icons {
    font-size: 60px;
    color: @title-color;
}

.dialogIcon {
    margin-bottom: @default-margin-value;
}

.k-dialog .k-content {
    background: transparent;
    max-width: 80vw;
    margin: 0 auto;
}

.k-dialog .dialogDescription {
    margin-bottom: @high-margin-value;
}

.k-dialog .largeTitle {
    margin-bottom: @high-margin-value;
}

.k-dialog-buttongroup {
    display: flex;
    justify-content: center;
}

.k-dialog-buttongroup .k-button {
    width: auto !important;
}

.k-dialog .k-button,
.k-dialog .k-button:active,
.k-dialog .k-button:focus,
.k-flatcolorpicker .k-primary.k-button,
.k-flatcolorpicker .k-button {
    border-radius: 50px;
    border: 1px solid @orphy-green;
    box-shadow: none !important;
    font-size: 15px;
    color: @orphy-green;
    height: auto;
    padding: 5px 20px;
    background: transparent;
    outline: 0;
    margin: 0 13px;
}

.k-button:active:hover {
    background: transparent;
    border: none;
}

.k-dialog .k-button.k-primary,
.k-flatcolorpicker .k-primary.k-button {
    background: @orphy-green;
    color: white;
}

.k-flatcolorpicker .k-primary.k-button,
.k-flatcolorpicker .k-button {
    padding: 3px 10px;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 18px;
}

.k-dialog-title {
    text-align: center;
}

.k-dialog .k-prompt-container {
    text-align: center;
    margin-bottom: @high-margin-value;
}

.k-dialog .largeTitle + p {
    margin-top: 0;
}

/* Window Confirmation End */
/* Bills Layout */
.billLayout {
    width: 100%;
    background: #f2f2f2;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    position: relative;
    vertical-align: top;
    font-size: @small-font-size;
}

.billLayoutFTU {
    .billLayout;
}

#billLayoutPreviewContainer,
#billLayoutPreviewContainerFTU {
    width: 100%;
    background: #f2f2f2;
    padding: 30px 30px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
}

.billLayoutPreviewWrapper .billLayoutPreviewRight,
.billLayoutPreviewWrapper {
    position: relative;
    vertical-align: top;
}

.billLayoutPreviewElement {
    overflow: scroll;
    text-overflow: ellipsis;
    position: absolute;
    background: @gray-color;
    display: block;
    border-radius: 2px;
    padding: 7px;
    overflow: hidden;
}

.billLayoutPreviewElement.editable:after,
.companyLogoWrapper .lfaPhoto:after {
    position: absolute;
    content: "\eae0";
    font-family: "caviar" !important;
    right: 0;
    top: 0;
    padding: 4px;
    background: rgba(255, 255, 255, 0.5);
    color: @orphy-green;
    pointer-events: none;
}

.billLayoutPreviewElement.editable:before {
    pointer-events: none;
    background: -moz-linear-gradient(top, rgba(204, 219, 224, 0) 0%, rgba(204, 219, 224, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(204, 219, 224, 0) 0%, rgba(204, 219, 224, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(204, 219, 224, 0) 0%, rgba(204, 219, 224, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ccdbe0', endColorstr='#ccdbe0', GradientType=0);
    /* IE6-9 */
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 50px;
}

.billLayoutPreviewElement.billLayoutPreviewElementActive.editable:before {
    display: none;
}

.billLayoutPreviewElementActive {
    background: @orange-color;
}

.logout-line {
    color: @inactive-color;
}

.logout-line .btnLarge i {
    font-size: 30px;
    margin-left: 10px;
}

.logout-line .btnLarge {
    line-height: 36px;
}

.billLeadinginBillSettings,
.billLeadingInFTU {
    height: 20px;
}

.billSenderAddressBillSettings,
.billSenderAddressFTU {
    height: 50px;
}

.billRecipientAddressBillSettings {
    height: 75px;
    margin-top: 60px;
}

.billMainText {
    height: 150px;
}

.billEndTextBillSettings,
.billEndTextFTU {
    height: 20px;
}

.billContactBillSettings,
.billContactDataFTU {
    height: 20px;
}

.billPaymentDetailsBillSettings,
.billPaymentDetailFTU {
    height: 75px;
}

.additionalEmail .editingInputFieldContainer {
    margin: 10px 10px 10px 0;
}

.billLogo {
    background-size: cover !important;
}

.companyLogoWrapper {
    position: absolute;
}

.companyLogoWrapper .lfaPhoto {
    padding: 0;
    width: 100%;
    justify-content: flex-end;
    position: relative;
}

.companyLogoWrapper .lfaPhoto img {
    max-width: 100%;
}

.companyLogoWrapper.default-flex {
    justify-content: flex-end;
}

.companyLogoWrapper .iconBtnLarge.iconOnly {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.companyLogoWrapper .iconBtnLarge.iconOnly i {
    width: 100%;
    margin-right: -2px;
}

.companyLogoWrapper .LFAPhotoStudentDetailImageWrapper {
    width: 100%;
}

.companyLogoWrapper p {
    margin: 0;
}

.companyLogoLegend {
    padding-left: 20px;
}

#imgCompanyLogo {
    max-width: 100%;
    max-height: 120px;
}

#companyLogoContainer {
    padding-top: 30px;
    justify-content: center;
    position: relative;
    height: 120px;
    width: 120px;
    min-width: 120px;
    box-sizing: border-box;
}

#companyLogoContainer.imageAdded {
    padding: 0;
    border: 1px dotted @orphy-green;
}

/* Bills Layout End */
img {
    max-width: 100%;
    height: auto;
}

.km-view .noPadding {
    padding-left: 0;
    padding-right: 0;
}

.km-view .noMargin {
    margin: 0;
}

.noWrap {
    white-space: nowrap;
}

.side-margin {
    margin-right: 16px;
}

.ellipsis,
.globalHeader .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.verticalBottom {
    vertical-align: bottom;
}

.verticalBase {
    vertical-align: baseline;
}

.wrap {
    white-space: normal;
}

.km-view .borderRight {
    border-right: 1px solid white;
}

/*.iconLink:after {
    font-family:"caviar" !important;
    text-align:center;
    height:40px;
    width:40px;
    line-height:40px;
    border:1px solid @orphy-green;
    border-radius:50%;
    box-sizing:border-box;
    padding:5px;
    margin-left:7px;
    position:absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    right:-50px;
    top:-15px;
}
.iconLinkPhone:after {
    content:"\eab5";
}*/
.iconLink {
    position: relative;
}

input[type="search"] {
    width: 100%;
}

body.km-flat .km-content {
    background: transparent;
    color: black;
    position: relative;
}

.wrappedMyDriveGalleryPicture {
    position: relative;
    display: inline-block;
}

.wrappedMyDriveGalleryPicture button {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.wrappedEditContentImage {
    position: relative;
    display: inline-block;
}

.wrappedEditContentImage.default-flex {
    align-items: flex-start;
}

.wrappedEditContentImage img {
    max-width: calc(~"100% - 100px");
    margin-bottom: 20px;
}

.editContentButtonWrapper button {
    display: block;
    margin: 6px 7px;
}

#chapterContentECC .myDriveGalleryPicture {
    border-bottom: 1px solid white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.editContentAddButtonContainer {
    margin-top: 20px;
}

#chapterContentECC .myDriveGalleryPicture:first-child .moveImageUp {
    display: none;
}

#chapterContentECC .myDriveGalleryPicture:last-child .moveImageDown {
    display: none;
}

#chapterContentECC .myDriveGalleryPicture + .myDriveGalleryPicture img {
    margin-top: 0;
}

body.km-flat div[data-model="MyDriveViews.educationPaperModel"] .km-content {
    padding-top: 0;
}

body.km-flat header + .km-content {
    padding-top: 25px;
}

#masterElementSubscriptionChooser .km-content.km-widget.km-scroll-wrapper {
    padding-top: 0;
}

#drawingMasterElement > div.km-content.km-stretched-view {
    padding-top: 0px;
}

#headerMap + .km-content {
    padding-top: 0;
}

.table.km-listview > li {
    display: table-row;
    width: 100%;
}

.km-flat .km-list > li {
    background: transparent;
    box-shadow: none;
    border-color: white;
    padding: 10px 0;
    margin-left: @default-padding-value;
    margin-right: @default-padding-value;
}

.km-flat .listFooterRow .km-list > li {
    display: table;
    margin-left: 0;
    margin-right: 0;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    table-layout: fixed;
}

.km-flat .listFooterRow.tableFixed .km-list > li {
    table-layout: fixed;
}

.km-widget .km-filter-wrap:before {
    font-family: "caviar";
    content: "\e906";
    position: absolute;
    right: 20px;
    margin-right: 0;
    top: calc(~"50% - 14px");
    overflow: visible;
    color: #9fa0a5;
}

.km-flat .km-listview-wrapper .km-filter-reset .km-clear {
    right: 40px;
}

.km-flat .km-listview-wrapper form .km-filter-wrap > input {
    padding: 15px 77px 15px 10px;
}

.km-listview-wrapper .km-filter-wrap {
    position: relative;
    margin-top: @default-padding-value;
    margin-bottom: @default-padding-value;
}

.km-content h3 {
    margin-left: 0;
    margin-right: 0;
}

.main-bg {
    background: url("img/driveswiss_bg.jpg");
    background-size: cover;
}

.white-back {
    background: @white-transparency;
}

.km-widget .km-content p {
    margin-left: 0;
    margin-right: 0;
}

.main-bg____OLD {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b9d8e2+0,d2dce5+39,dbdbe8+100 */
    background: #b9d8e2;
    /*Old browsers*/
    background: -moz-linear-gradient(-45deg, #b9d8e2 0%, #d2dce5 39%, #dbdbe8 100%);
    /*FF3.6-15*/
    background: -webkit-linear-gradient(-45deg, #b9d8e2 0%, #d2dce5 39%, #dbdbe8 100%);
    /*Chrome10-25,Safari5.1-6*/
    background: linear-gradient(135deg, #b9d8e2 0%, #d2dce5 39%, #dbdbe8 100%);
    /*W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b9d8e2', endColorstr='#dbdbe8', GradientType=1);
    /*IE6-9 fallback on horizontal gradient*/
}

.white-gradient-bg {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+33&0.3+0,0+33 */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 33%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 33%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 33%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dffffff', endColorstr='#00ffffff', GradientType=0);
    /* IE6-9 */
}

.main-menu-bg {
    background: rgba(0, 0, 0, 0)
        linear-gradient(
            135deg,
            #0a1239 0%,
            #0c133d 19%,
            #0f1841 26%,
            #0d193f 29%,
            #101942 29%,
            #0d1941 30%,
            #0e1c3f 54%,
            #112142 60%,
            #0d2740 78%,
            #0e2a40 81%,
            #0e2c44 83%,
            #0d2d42 84%,
            #163f51 95%,
            #184354 98%,
            #174252 99%,
            #194454 100%
        )
        repeat scroll 0 0;
}

hr.white-divider {
    height: 1px;
    background: white;
    border: none;
    margin-right: @default-padding-value;
    margin-left: @default-padding-value;
}

hr.input-divider {
    margin-bottom: 20px;
    height: 1px;
    background: white;
    border: none;
}

hr.white-divider.fullWidth {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

hr.examDivider.white-divider {
    margin: 25px 0 0px 0;
    display: block;
}

/* Menu Styling End*/
.globalHeader {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
    background: white;
    margin-bottom: 0;
    min-height: @header-height;
    max-height: @header-height;
    height: @header-height;
}

#educationCardMasterElement .globalHeader {
    margin-bottom: 0;
}

.km-on-ios .globalHeader {
    padding-top: 0;
}

.globalHeader .headerTitle {
    flex: 1;
    flex-grow: 2;
}

.globalHeader .headerActionButton {
    flex: 2;
}

.headerActionButton.text-right {
    min-width: 112px;
}

.globalHeader .headerBackButton {
    white-space: nowrap;
    overflow: hidden;
    flex: 2;
}

.globalHeader .flex1 {
    flex: 1;
}

.globalHeader .flex2 {
    flex: 2;
}

.globalHeader:after,
.headerReadonlyNotification:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    content: "";
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8848fe+0,00cde2+99 */
    background: #8848fe;
    /* Old browsers */
    background: -moz-linear-gradient(left, #8848fe 0%, #00cde2 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #8848fe 0%, #00cde2 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #8848fe 0%, #00cde2 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8848fe', endColorstr='#00cde2', GradientType=1);
    /* IE6-9 */
}

.headerReadonlyNotification {
    position: relative;
    background: @red-color;
    color: white;
}

.headerReadonlyNotification.default-flex {
    align-items: stretch;
}

.headerReadonlyIcon {
    padding: 15px;
    background: #c44142;
}

.headerReadonlyNotification .headerReadonlyText {
    padding-top: 5px;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.headerReadonlyIcon i {
    font-size: 40px;
}

.showHeaderReadonly .globalHeader:after {
    display: none;
}

.headerReadonly .headerReadonlyNotification {
    display: none;
}

.showHeaderReadonly.headerReadonly .headerReadonlyNotification {
    display: flex;
}

.mainMenu {
    padding: 10px 0px;
}

header {
    background: none;
    margin: 0;
    padding: 0;
}

.widthDelimiter {
    height: inherit;
    width: 80%;
    margin: 0 auto;
}

.headerActionButton .autoWidth.iconOnly {
    width: auto;
}

.headerBackButton .iconOnly i,
.headerActionButton .iconOnly i {
    display: inline-block;
    vertical-align: middle;
}

.headerActionButton .iconBtnLarge.iconOnly.iconOnlyFilled {
    width: 42px;
}

.headerActionButton .iconBtnLarge.iconOnlyFilled + .iconOnly {
    width: auto;
}

.globalHeader .iconOnly i {
    font-size: 30px;
    overflow: visible;
}

.globalHeader .iconOnly .icon-pencil {
    padding-top: 12px !important;
    padding-bottom: 2px !important;
    font-size: 28px;
}

.headerBackButton .iconOnly .btnText {
    font-size: 1em;
    padding-left: 7px;
    margin-left: 0;
    width: calc(~"100% - 30px");
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-family: inherit;
    line-height: 1.4;
    box-sizing: border-box;
}

.headerActionButton .iconOnly .btnText {
    font-size: 1em;
    line-height: 1.4;
}

.headerBackButton .iconOnly {
    width: 100%;
    text-align: left;
    font-family: inherit;
    height: auto;
    padding-right: 0;
}

.headerActionButton .iconOnly {
    text-align: right;
    font-family: inherit;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
}

.headerTitle {
    text-align: center;
    flex: 1;
    justify-content: center;
    overflow: visible;
    text-overflow: ellipsis;
}

.smallTitle {
    font-weight: @font-weight-light;
    font-size: @very-small-font-size;
}

.largeTitle {
    font-weight: @font-weight-light;
    font-size: @extra-large-font-size;
    line-height: 1.3em;
    color: @title-color;
}

.largeIcon {
    font-size: 30px;
}

.largeNumber {
    font-size: 40px;
    font-weight: 300;
    line-height: 1;
}

.veryLargeTitle {
    font-weight: @font-weight-normal;
    font-size: @extra-large-font-size;
}

.subHeading {
    font-weight: @font-weight-bold;
}

.noData .noDataMessage {
    margin-left: 7px;
}

.noData.default-flex {
    align-items: flex-start;
}

.noData i {
    margin-top: 5px;
}

.km-widget .headerTitleSpan {
    color: @title-color;
}

.btn {
    border-radius: 50px;
    height: 48px;
    display: inline-block;
    border: 1px solid @orphy-green;
    padding: 0px 20px;
    white-space: nowrap;
    background: @orphy-green;
    color: white;
    box-sizing: border-box;
    font-size: 16px;
}

.btn:active {
    background: @orphy-green-hover;
}

.inactiveButton,
.inactiveButton span {
    color: @inactive-color;
}

.btnTransparent {
    background: transparent;
    border: none;
}

.btnNegative {
    background: white;
    color: @orphy-green;
    border-color: white;
}

.btn.btnNegative:active {
    color: white;
    background: @orphy-green;
    border-color: @orphy-green;
}

.btn.btnTransparent:active {
    background: transparent;
    border: none;
    color: darkgray;
}

.k-state-hover .k-button:active,
.k-state-focused .k-button:active {
    background: transparent !important;
    color: @title-color;
}

.btn.k-state-focused,
.btn .k-state-active {
    background: transparent;
}

.btnLarge,
.btnLarge i {
    font-size: 36px;
}

button .btnText,
.btn i {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}

button i + .btnText {
    margin-left: 5px;
}

.btnText + i {
    margin-left: 7px;
    font-size: 22px;
}

.btnNoBack {
    background: transparent;
    color: @orphy-green;
}

.noButtonEffect {
    background: transparent;
    border: none;
}

.btn.disabledButton,
.disabledButton {
    pointer-events: none;
    opacity: 0.3;
}

.btn.disabled,
.disabled {
    pointer-events: none;
    opacity: 0.3;
}

.btn.btnOutline.disabledButton {
    background: transparent;
    opacity: 0.5;
}

.btnOutline {
    background: transparent;
    border: 1px solid @orphy-green;
    color: @orphy-green;
}

.btnOutline:active {
    border-color: @orphy-green-hover;
    color: @orphy-green-hover;
    background: transparent;
}

.btnPopup {
    background: none;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
}

.btnFilled {
    background: @orphy-green;
    color: white;
}

.btnTab {
    background: transparent;
    transition: all ease @default-transition-duration;
    color: black;
    box-sizing: border-box;
    padding: 10px 17px;
    border: none;
    position: relative;
    font-size: @default-font-size;
    font-family: inherit;
}

.btnTab:active {
    background: @white-transparency-hover;
}

.tabActive:active {
    background: @white-transparency-hover;
}

.tabActive,
.tabActivePaperwork {
    background: @white-transparency;
}

#LFAandExamStudentDetail {
    padding-top: 0;
}

.subscriptionsBillSentTitle {
    min-width: 150px;
}

.subscriptionsBoughtTitle {
    min-width: 70px;
}

.subscriptionsBoughtTitle div {
    line-height: 1;
}

.paymentTypeTitle {
    margin-top: 30px;
}

.accountLessonStudentInfo {
    margin-top: 30px;
}

.jobPositionList {
    max-height: 300px;
    overflow: auto;
    min-height: 100px;
}

.jobPositionList .k-checkbox-label {
    pointer-events: none;
}

.jobPositionListTotal {
    padding-top: 15px;
}

.accountLessonAvatar {
    margin-right: 15px;
}

.lessonAccountLessonRow {
    border-bottom: 1px solid white;
    padding-bottom: 15px;
}

.lessonAccountLessonName label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(~"100% - 22px");
}

.subscriptionsStateTitle {
    min-width: 225px;
    white-space: nowrap;
}

#subscriptionsStateTitle {
    width: 285px;
}

.billsPaperworkState {
    min-width: 129px;
}

.billsPaperworkChecklistName {
    max-width: 115px;
}

.billTrashTableCell {
    min-width: 25px;
    text-align: right;
}

.subscriptionPrice {
    min-width: 70px;
}

.getLessonCountCell {
    min-width: 165px;
}

.getLessonCountCellButtons {
    margin-left: auto;
}

.invoicesNameCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20vw;
}

.subscriptionsSettleTitle {
    min-width: 117px;
}

.billsPaperworkSumCell {
    min-width: 90px;
}

.billTrashTableCell {
    padding-left: 0 !important;
}

.billTrashTableCell .iconOnly {
    padding-left: 0;
    padding-right: 0;
    width: 25px;
    min-width: initial;
    height: auto;
    min-height: initial;
    padding-top: 0;
    padding-bottom: 0;
}

.billTrashTableCell .iconOnly i {
    margin: 0;
    width: 26px;
}

.tabLine {
    text-align: center;
    white-space: nowrap;
}

.btnPlanLesson {
    width: 20%;
    border-style: solid;
    position: fixed;
    top: 35%;
    left: 40%;
    background: white;
    border-radius: 50%;
    border-width: 0.1em;
}

/* Notifications*/
.notificationContainer {
    color: white;
    border: none;
    padding: 20px @default-padding-value;
    white-space: nowrap;
    box-sizing: border-box;
    position: relative;
}

.infoNotification {
    background: #76929e;
}

.successNotification {
    background: @green-color;
}

.errorNotification {
    background: @red-color;
}

.notificationButton {
    background: transparent;
    border: none;
    color: #4234ef;
    text-decoration: underline;
}

.km-pane .k-widget.k-notification {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.k-animation-container {
    padding: 0 !important;
}

.notificationContainer i {
    font-size: 48px;
}

.notificationMessage {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    white-space: normal;
}

#removeMessages {
    position: absolute;
    right: 10px;
    top: 10px;
}

#removeMessages i {
    font-size: @large-icon-font-size;
}

/* Icon Text*/
.meetingPointIcon:after {
    position: absolute;
    right: 0;
}

/* Icon Text End*/
/* Header Infos*/
.overviewInfoContainer {
    margin-bottom: 25px;
    white-space: nowrap;
}

.overviewInfoValue {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

#educationCardRatingHeader.gridTable .tableCell {
    padding-top: 0;
    padding-bottom: 0;
}

#contentEducationCard .overviewInfoContainer {
    min-height: 47px;
    overflow-y: hidden;
    max-height: 50px;
}

#chapterTitleEducationCard {
    padding-bottom: 7px;
    border-bottom: 1px solid white;
    padding-top: 12px;
}

.overviewButtonContainer,
.defaultButtonContainer {
    margin-left: 8px;
}

.overviewInfoContent + .overviewButtonContainer {
    margin-left: 13px;
}

.overviewInfoContent.overviewInfoContentMail {
    max-width: calc(~"100% - 50px");
}

.defaultButtonContainer {
    display: inline-block;
}

#noteHeaderStudentDetail > p:first-child {
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popupContentHeightSelector > p {
    padding: 0;
    margin: 0;
}

.km-content .StudentDetailNoteInfo {
    padding-left: 30px;
}

#noteHeaderStudentButton {
    margin-top: -12px;
}

.popupCloseButton {
    position: absolute;
    top: 5px;
    right: 5px;
}

.subscriptionState {
    width: 15px;
    height: 15px;
    background: @white-transparency;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 7px;
}

.subscription-tick-mark {
    margin-right: 7px;
}

.subscriptionState.open {
    background: @orange-color;
}

.overviewInfoLabel {
    font-weight: @font-weight-bold;
}

/* Header Infos End*/
.switch {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 60px;
    height: 34px;
}

.studentDetailExamCheckboxes {
    padding: 12px 0 20px 0;
}

.studentDetailExamCheckboxes > div {
    width: 25%;
}

.examDateStudentDriverButtons input[type="radio"]:not(old) + label > span {
    margin-bottom: 0.65em;
}

.examDateStudentDriverButtons > div:last-child {
    margin-left: 12px;
}

.examStudentDriver {
    padding-top: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
}

.vkusFinished {
    padding-bottom: 20px;
}

.examStudentDriver .studentDtailExamDatepicker label,
.examStudentDriver .studentDtailExamDatepicker input {
    line-height: 1;
}

.studentDtailExamDatepicker {
    width: 175px;
}

.examStudentDriver.invisible {
    pointer-events: none;
}

.arrowPlanLesson {
    width: 60%;
    position: absolute;
    top: 20%;
    left: 20%;
}

#selectedLessonsViewPlanLesson {
    position: relative;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

#header + .buttonWrapper {
    padding-top: 25px;
}

/* Dashboard Styling*/
.profileNameMainView-inner {
    margin-bottom: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard-graphics .ratingGraphicContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0 3%;
}

.dashboard-graphics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: @small-font-size;
}

.ratingGraphicLegend div,
.ratingGraphicLegend label {
    display: inline-block;
    vertical-align: middle;
}

.ratingGraphicInner .ratingGraphicLegend:first-child {
    margin-top: 40px;
}

.ratingGraphicLegend {
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
}

.ratinglegendDot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 7px;
    margin-top: 2px;
}

.ratinglegendDotNotRated {
    background-color: #ffffff;
}

.dashboard-graphics-circle {
    width: 120px;
    height: 120px;
    border: 17px solid @red-color;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 0 auto;
}

.dashboard-graphics-label {
    text-align: center;
    /*margin-bottom: @default-margin-value;*/
    width: 100%;
    display: block;
}

.dashboard-graphics-container {
    text-align: center;
}

.graphic-wrapper {
    width: 33%;
}

/* Dashoard Styling End*/
.subTitle {
    background-color: @white-transparency;
    padding-left: 10px;
    padding-top: 5px;
    margin-top: 10px;
    padding-bottom: 5px;
}

#toggleInactiveStateContainer .smallText {
    margin-right: 10px;
}

.inputField {
    background: none;
    border: none;
    border-radius: 0px;
    margin-bottom: 5px;
    width: 100%;
    padding: 0;
    position: relative;
}

.inputFieldNumeric {
    // background: none;
    // border: none;
    border-radius: 0px;
    width: 100%;
    padding: 0;
    margin: 0;
}

.searchInputField {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    background: @white-transparency;
    padding: 15px 35px 15px 10px;
    background-size: 20px 20px;
    font-family: inherit;
    outline: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.km-on-ios .searchInputField {
    width: 95%;
}

.km-on-ios #searchHeaderAllStudents {
    text-align: right;
}

.searchHeaderAllStudents {
    width: calc(~"100% - 80px");
    position: relative;
}

#searchHeaderAllStudents:after {
    content: "\e906";
    position: absolute;
    right: 40px;
    top: 19px;
    color: #9fa0a5;
    font-size: 22px;
    font-family: "caviar" !important;
}

#controlHeaderAllStudents .default-flex {
    align-items: flex-end;
}

#controlHeaderPaperworkBills .default-flex,
#controlHeaderPaperworkBills .default-inline-flex {
    align-items: flex-end;
}

#controlHeaderPaperworkBills {
    margin-top: 25px;
}

.default-flex.noteHeaderStudent {
    align-items: flex-start;
}

.addTimeTrackingForm {
    background: @white-transparency;
    padding-top: @default-margin-value;
    padding-bottom: @default-margin-value;
}

.timeTrackingLessonWith {
    min-width: 390px;
}

.timeTrackingAddButton i {
    margin: 0 0 -2px -3px;
}

.timeTrackingAddButton.iconOnly {
    width: auto;
}

.timeTrackingTableTitle {
    width: 55%;
}

.timeTrackingTableTime {
    width: 70px;
}

.dateInputField {
    border-style: solid;
    border-width: thin;
    padding: 10px 10px 10px 10px;
    background-position: 90% 7.5px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
}

.fullWidth {
    width: 100%;
}

.smallText {
    font-size: @small-font-size;
}

.mediumText {
    font-size: @medium-font-size;
}

.km-widget .default-padding {
    padding-left: @default-padding-value;
    padding-right: @default-padding-value;
}

.default-inline-block {
    display: inline-block;
    vertical-align: middle;
}

.default-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flex-bottom {
    align-items: flex-end;
}

.flex-50 div {
    flex: 1;
}

.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}

.flex3 {
    flex: 3;
}

.flex4 {
    flex: 4;
}

.flex5 {
    flex: 5;
}

.default-inline-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-center {
    justify-content: center;
}

.invisible {
    opacity: 0;
    pointer-events: none;
}

.violetText {
    color: @violet-color;
}

.dividerTop {
    padding-top: @default-margin-value;
}

.km-view .default-margin {
    margin-bottom: @default-margin-value;
}

.padding-bottom {
    padding-bottom: 30px;
}

.km-view .high-margin {
    margin-bottom: @high-margin-value;
}

.clearfix:after,
.row-fluid:after {
    content: "";
    display: table;
    clear: both;
}

div[class^="span"] {
    float: left;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
}

div[class^="span"] + div[class^="span"]:last-child {
    padding-right: 0;
}

div.span12:last-child {
    padding-right: 0;
}

.tableRow .iconCell.tableCell {
    width: 55px;
    height: 55px;
    text-align: center;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
}

.tableRow.missedLessonRow {
    color: @inactive-color;
}

.gridTable .headerTableRow .tableCell:first-child {
    padding-left: 15px;
}

.tableCell-span1 {
    width: 8.3333%;
}

.tableCell-span2 {
    width: 16.6666%;
}

.tableCell-span3 {
    width: 25%;
}

.tableCell-span4 {
    width: 33.3333%;
}

.tableCell-span5 {
    width: 41.6665%;
}

.tableCell-span6 {
    width: 50%;
}

.tableCell-span7 {
    width: 58.3331%;
}

.tableCell-span8 {
    width: 66.6664%;
}

.tableCell-span9 {
    width: 75%;
}

.tableCell-span10 {
    width: 83.3333%;
}

.tableCell-span11 {
    width: 91.6663%;
}

.tableCell-span12 {
    width: 100%;
}

.offset1 {
    margin-left: 8.3333%;
}

.offset2 {
    margin-left: 16.6666%;
}

.offset3 {
    margin-left: 25%;
}

.offset4 {
    margin-left: 33.3333%;
}

.offset5 {
    margin-left: 41.6665%;
}

.offset6 {
    margin-left: 50%;
}

.offset7 {
    margin-left: 58.3331%;
}

.offset8 {
    margin-left: 66.6664%;
}

.offset9 {
    margin-left: 75%;
}

.offset10 {
    margin-left: 83.3333%;
}

.offset11 {
    margin-left: 91.6663%;
}

.offset12 {
    margin-left: 100%;
}

.tableCell-offset1 {
    padding-left: 8.3333%;
}

.tableCell-offset2 {
    padding-left: 16.6666%;
}

.tableCell-offset3 {
    padding-left: 25%;
}

.tableCell-offset4 {
    padding-left: 33.3333%;
}

.tableCell-offset5 {
    padding-left: 41.6665%;
}

.tableCell-offset6 {
    padding-left: 50%;
}

.tableCell-offset7 {
    padding-left: 58.3331%;
}

.tableCell-offset8 {
    padding-left: 66.6664%;
}

.tableCell-offset9 {
    padding-left: 75%;
}

.tableCell-offset10 {
    padding-left: 83.3333%;
}

.tableCell-offset11 {
    padding-left: 91.6663%;
}

.tableCell-offset12 {
    padding-left: 100%;
}

.tableFixed {
    table-layout: fixed;
}

.listTable .km-list {
    display: table;
    width: 100%;
    padding-left: @default-padding-value;
    padding-right: @default-padding-value;
}

.listTable.tableFixed .km-list {
    table-layout: fixed;
}

.listTable .km-list > li {
    display: table-row;
}

.listTable .km-list > li > .tableCell {
    height: 70px;
    border-bottom: 1px solid white;
    padding: 10px 15px 10px 15px;
}

.listTable .km-list > li .tableCell:first-child {
    padding-left: 0;
}

.listTable .km-list > li .tableCell:last-child {
    padding-right: 0;
}

.listTable .km-list > li:last-child .tableCell {
    border-bottom: none;
}

.tableCell .btn {
    height: auto;
    padding: 5px 8px;
}

.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

.tableRow {
    display: table-row;
    width: 100%;
}

.tableVerticalAlignBottom .tableCell {
    vertical-align: bottom;
}

#billsAndSubsStudentDetail .table label,
#billsAndSubsStudentDetail .table input {
    display: inline; //TODO: checkout => checkbox border on ipad
}

.table label,
.table input {
    display: table-cell;
}

.highCells .tableCell {
    height: 70px;
}

.gridTable .tableCell {
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid white;
}

.gridTable .titleRow .tableCell,
.gridTable .dailyTotalRow .tableCell {
    border: none;
}

.dailyTotalRow .tableCell:first-child,
weeklyTotalRow .tableCell:first-child,
.monthlyTotalRow .tableCell:first-child {
    width: 100px;
}

.listFooterRow .dailyTotalRow .tableCell,
.listFooterRow .weeklyTotalRow .tableCell,
.listFooterRow .monthlyTotalRow .tableCell {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: none;
}

.listFooterRow li .tableRow:first-child .tableCell {
    padding-top: 5px;
}

.timeTrackingSumTable {
    margin-top: 10px;
    margin-bottom: 0;
}

.gridTable.headingTable .tableCell {
    border-bottom: none;
}

.stripedTable .tableCell {
    border-bottom: none;
}

.gridTable .tableRow:last-child .tableCell {
    border-bottom: none;
}

.gridTable .tableCell:first-child {
    padding-left: 0;
}

.gridTable .tableCell:last-child {
    padding-right: 0;
}

.gridTable {
    margin-left: @default-padding-value;
    margin-right: @default-padding-value;
    width: calc(~"100% - 60px");
}

.innerTable.gridTable {
    margin: 0;
    width: 100%;
}

#fullChecklistEducationCard .tableCell .fullWidth {
    padding-left: 15px;
    padding-right: 15px;
}

#fullChecklistEducationCard {
    margin-bottom: 20px;
}

#buttonHeaderEducationCard {
    background: @white-transparency;
    padding-top: @default-margin-value;
    padding-bottom: @default-margin-value;
}

.educationPaperNoteCell {
    width: 60px;
}

.saveRatingButton {
    margin-left: auto;
}

.lastRating a:link {
    text-decoration: none;
}

.lastRating i {
    font-size: 25px;
    margin-top: 3px;
    margin-right: 5px;
}

.checkListNotesTitle .icons {
    font-size: @large-icon-font-size;
}

.checkListNotesTitleText {
    padding-left: 10px;
}

.checkListNoteListRow {
    border-top: 1px solid white;
    padding: 10px 0;
}

.checkListNoteDate {
    padding-left: 3px;
}

.noNote .icons {
    color: @inactive-color !important;
}

.checkListNoteInfo {
    margin-bottom: 2px;
}

.checkListNoteEditingSaveButton {
    min-width: 70px;
}

.checkListNotesEditingRow {
    margin-bottom: 30px;
}

#fullChecklistEducationCard .EducationCardChapterTitleRow .tableCell:first-child .chapterTitle:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    background: white;
    left: -9999px;
    right: 45px;
    width: 20000px;
    margin-top: 15px;
}

#fullChecklistEducationCard .chapterTitle {
    position: relative;
    padding-bottom: 10px;
}

.EducationCardChapterTitleRow .tableCell {
    border-bottom: none;
}

#educationCard .chapterTitle {
    font-weight: normal;
    font-size: @default-font-size;
    font-weight: @font-weight-bold;
}

.stripedTable .tableCell:first-child {
    padding-left: 15px;
}

.stripedTable .tableRow:nth-child(even) {
    background: @white-transparency;
}

.stripedTableInverse .tableRow:nth-child(odd) {
    background: rgba(185, 216, 226, 0.2);
}

.stripedTableInverse .tableRow .tableCell {
    border-top: 1px solid #ddd;
    padding: 10px 15px 10px 15px;
}

.stripedTableInverse {
    margin-bottom: @default-margin-value;
}

.tableCell {
    display: table-cell;
    vertical-align: middle;
}

textarea {
    width: 100%;
    height: 100px;
    padding: 15px;
    font-family: inherit;
}

.inputPlaceholder {
    border: none;
    background: none;
}

.labelAboveForm label {
    display: block;
}

.divAbove span {
    display: block;
}

.inputFieldContainerDrawing {
    background: none;
    position: relative;
    display: block;
    box-sizing: border-box;
}

.inputFieldContainer {
    margin-bottom: 30px;
    background: @white-transparency;
    border-radius: 4px;
    padding: 8px 18px 3px 18px;
    position: relative;
    height: 80px;
    display: block;
    outline: none;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0);
}

.inputFieldContainer input {
    outline: none;
}

.inputFieldContainer.maxWidth {
    max-width: 125px;
}

.inputFieldContainer.invalid,
.editingInputFieldContainer.invalid {
    border: 1px solid @red-color;
}

.inputFieldContainer.required label:after {
    content: "*";
    color: @red-color;
    font-size: 90%;
    margin-left: 4px;
}

.inputFieldContainer .k-tooltip-validation,
.editingInputFieldContainer .k-tooltip-validation {
    position: absolute;
    bottom: -15px;
    left: -1px;
    border: none;
    background: @red-color;
    color: white;
    border-radius: 0;
    width: calc(~"100% + 2px");
    right: 0;
    box-sizing: border-box;
    box-shadow: none;
    font-size: 11px;
    padding: 0 7px;
    height: 18px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
}

.inputFieldContainer .intl-tel-input .country-list {
    z-index: 10000;
}

.inputFieldContainer .k-tooltip-validation .k-icon {
    display: none;
}

.intl-tel-input {
    width: 100%;
}

.editingInputFieldContainer input {
    width: 100%;
    margin: 0;
    outline: none;
}

.editingInputFieldContainer {
    background: white;
    border-radius: 4px;
    width: 100%;
    padding: 6px 13px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0);
}

.popupContent .inputFieldContainer {
    border: 1px solid @gray-color;
}

.popupContent .inputFieldContainerFocused {
    border-color: @orphy-green;
}

.inputFieldContainerFocused {
    border: 1px solid @orphy-green;
}

.k-dropdown-wrap .k-input {
    text-indent: 0;
    font-size: 25px;
    margin-top: 1px;
    margin-bottom: 5px;
    padding: 1px 31px 0 0;
    line-height: normal;
    height: auto;
}

.k-popup .k-list .k-item {
    padding: 10px 12px;
}

.k-dropdown .k-i-arrow-60-down:before,
.k-colorpicker .k-i-arrow-60-down:before {
    content: "\e915";
    font-family: "caviar" !important;
    font-size: 35px;
}

#drawingMasterElement .k-dropdown .k-i-arrow-60-down:before {
    content: "\e915";
    font-family: "caviar" !important;
    font-size: 24px;
    background: none;
}

#drawingMasterElement .k-colorpicker .k-i-arrow-60-down:before {
    font-size: 18px;
    content: "\e915";
    font-family: "caviar" !important;
}

#drawingMasterElement .k-dropdown .k-i-arrow-60-down {
    height: 16px;
    color: white;
}

#drawingMasterElement .k-dropdown,
#drawingMasterElement .k-dropdown-wrap.k-state-default {
    color: white;
    padding-right: 0px;
    font-size: @small-font-size;
    background: none;
}

#drawingMasterElement .k-dropdown .k-input {
    color: white;
    font-size: 14px;
    padding: 8px 31px 0 0;
}

.k-colorpicker .k-i-arrow-60-down {
    background: none;
}

.paperWorksBillFooter.km-footer {
    background: transparent;
}

.paperWorksBillFooter .k-pager-wrap .k-link {
    border-color: @orphy-green;
}

.paperWorksBillFooter .k-pager-wrap .k-link:active {
    background: @orphy-green-hover;
}

.paperWorksBillFooter .k-pager-wrap .k-link:hover {
    background: unset;
    color: @orphy-green;
}

.paperWorksBillFooter {
    color: @orphy-green;
}

.paperWorksBillFooter .k-pager-info.k-label {
    font-size: 14px;
}

.paperWorksBillFooter .k-icon {
    vertical-align: initial;
}

.paperWorksBillFooter .k-pager-wrap > .k-link {
    min-width: 4.5em;
    margin-right: 0.5em;
    transition: all ease @default-transition-duration;
    float: none;
    margin-bottom: 5px;
    margin-top: 5px;
}

.paperWorksBillFooter .k-pager-wrap > .k-link.k-pager-last,
.paperWorksBillFooter .k-pager-wrap > .k-link.k-pager-first {
    background: @orphy-green;
    color: white;
}

.paperWorksBillFooter .k-pager-wrap > .k-link:hover {
    background: @orphy-green-hover;
    color: white;
}

.paperWorksBillFooter .k-pager-wrap > .k-link.k-state-disabled {
    opacity: 0.3;
}

.paperWorksBillFooter .k-pager-wrap {
    padding: 0.666em 0 0.666em 0.5em;
    background: @white-transparency !important;
}

.paperWorksBillFooter .k-i-seek-w:before {
    content: "\e90f";
    font-family: "caviar" !important;
    font-size: 15px;
}

.paperWorksBillFooter .k-i-arrow-w:before {
    content: "\e914";
    font-family: "caviar" !important;
    font-size: 15px;
}

.paperWorksBillFooter .k-i-arrow-e:before {
    content: "\e916";
    font-family: "caviar" !important;
    font-size: 15px;
}

.paperWorksBillFooter .k-i-seek-e:before {
    content: "\e910";
    font-family: "caviar" !important;
    font-size: 15px;
}

.km-flat.km-widget .km-popup .k-list-container {
    height: 80% !important;
}

.k-dropdown .k-i-arrow-60-down {
    background: none;
    width: 30px;
    height: auto;
    font-size: 16px;
    color: @orphy-green;
}

.inputFieldContainer .k-dropdown-wrap.k-state-default,
.dropdownContainer .k-dropdown-wrap.k-state-default {
    background: none transparent;
    border: none;
    padding: 0;
    min-height: 35px;
}

.dropdownContainer .k-dropdown {
    width: 100%;
}

.inputFieldContainer .k-dropdown-wrap.k-state-focused,
.inputFieldContainer .k-dropdown-wrap.k-state-active,
.dropdownContainer .k-dropdown-wrap.k-state-focused,
.inputFieldContainer .k-dropdown-wrap.k-state-active {
    background: none transparent;
    border: none;
    box-shadow: none;
}

.inputFieldContainerTransparent {
    background: transparent;
    margin-left: 12px;
}

.inputFieldContainerWrapper {
    margin-bottom: 30px;
}

.inputFieldContainerWrapper .inputFieldContainer {
    margin-bottom: 0;
    margin-right: 12px;
}

.inputFieldContainerWrapper .inputFieldContainer:last-child {
    margin-right: 0;
}

.inputFieldContainer .inputField {
    margin-top: 1px;
    width: 100%;
}

.inputFieldContainer label {
    font-size: 15px;
    color: #717171;
    line-height: 1;
}

.inputFieldContainerTextarea {
    height: auto;
    padding: 10px 15px;
}

.inputFieldContainerTextarea.inputFieldContainer textarea {
    border: none;
    background: transparent;
    padding: 15px 0;
    font-size: 25px;
}

.billLayoutPreviewElement p {
    margin: 0px;
}

.inputFieldContainer.inputFieldContainerRadio label {
    left: 0;
}

.inputFieldContainer.inputFieldContainerRadio label:first-child {
    left: 15px;
}

.inputFieldContainer .iconOnly {
    height: auto;
    width: auto;
    position: absolute;
    right: 13px;
    top: 15px;
}

.inputFieldContainer .iconOnly i {
    font-size: 40px;
}

.addEmailButton i {
    margin-top: -2px;
}

.radioWrapper {
    margin-top: 7px;
}

.radioWrapper label {
    font-size: @default-font-size;
    color: black;
    margin-right: 15px;
}

.inputFieldContainer.inputFieldContainerRadio {
    background: transparent;
}

.inputFieldContainerRadio {
    margin-bottom: @default-margin-value;
}

.inputFieldContainer input {
    font-size: 25px;
}

.inputFieldContainer input[type="datetime-local"] {
    padding-right: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inputFieldContainer .k-numerictextbox .k-numeric-wrap {
    background: transparent;
    border: none;
}

.inputFieldContainer .k-numerictextbox .k-numeric-wrap input {
    font-size: 25px;
    text-indent: 0;
    line-height: 1;
    margin: 0;
    padding: 0;
}

.inputFieldContainer input[type="radio"] + label {
    position: relative;
}

input[type="radio"] + label em {
    font-style: normal;
}

.radioWrapper input[type="radio"]:not(old) + label {
    margin-left: 0;
    height: auto;
    padding-left: 30px;
    line-height: 1.6;
}

.radioWrapper input[type="radio"]:not(old) + label > span {
    margin-left: -30px;
    position: absolute;
    margin-top: 5px;
}

.inputFieldContainer input[type="radio"] {
    margin-top: 25px;
}

.labelTransition label {
    transition: all ease @default-transition-duration;
}

.inputFieldContainer.noFill label {
    font-size: @default-font-size;
    color: #717171;
    top: 30px;
}

//need when buttons need some space around
.buttonContainer {
    padding: 15px 15px 15px 0;
}

.table .buttonContainer {
    padding-top: 0;
    padding-bottom: 0;
}

.floating-panel {
    position: absolute;
    top: 60vh;
    left: 10vw;
    z-index: 5;
    text-align: center;
    font-family: "Roboto", "sans-serif";
}

.search-floating-panel {
    position: absolute;
    top: 10px;
    left: 30%;
    color: black;
    z-index: 5;
    text-align: center;
    font-family: "Roboto", "sans-serif";
    line-height: 30px;
}

.reducedHrLength {
    margin: 10px 0;
    height: 1px;
    background: #c5c5c5;
    border: none;
}

.tabContent {
    margin-top: 10px;
    .white-gradient-bg;
    padding-top: 25px;
}

.lessonSideBar {
    background: @white-transparency;
    float: left;
    width: 350px;
    margin-left: -350px;
    transition: all ease @default-transition-duration;
}

.lessonSideBarButton {
    align-self: flex-start;
    margin-top: 4px;
    margin-right: 20px;
    margin-left: 0;
}

.lessonSideBarButton.lessonSideBarButtonInvisible {
    display: none;
}

.lessonSideBar.sideBarVisible {
    margin-left: 0;
}

#saveLessonPlanLesson .icon-restore {
    transform: scaleX(-1);
}

.startLessonWrappers {
    margin-top: 30px;
}

.startLessonWrappers button {
    display: block;
    text-align: left;
    width: 100%;
    margin: 20px 0;
}

.startLessonWrappers button i + .btnText {
    margin-left: 10px;
}

.startLessonWrappers button:first-child {
    margin-top: 20px;
}

.lessonSideBar .planLesson .chapterTitle {
    background: transparent;
}

#allLessonsEducationPaper .chapterTitle {
    padding-left: 15px;
    padding-right: 15px;
}

#sideBarEducationPaper .dropdownContainer {
    padding-top: 10px;
    padding-bottom: 10px;
}

.contentEducationPaper {
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
    transition: all ease @default-transition-duration;
}

.chapterContentPlanLesson {
    min-width: 100vw;
    position: relative;
    transition: margin-left ease @default-transition-duration;
}

.myDriveGalleryPicture > img {
    margin-bottom: 7px;
}

.myDriveGalleryPicture + .myDriveGalleryPicture img {
    margin-top: 25px;
}

#chapterContentPlanLesson {
    transition: all ease @default-transition-duration;
}

.contentEducationPaperLessonHeader {
    padding-bottom: 10px;
}

#lessonContentEducationPaper {
    transition: all ease @default-transition-duration;
    padding-top: @default-margin-value;
}

#lessonContentEducationPaperInner {
    width: calc(~"100vw - 60px");
}

#educationPaper {
    overflow: hidden;
    height: 100%;
}

#educationPaper .lessonItemInner {
    white-space: normal;
    padding: 5px 0;
    margin: 0;
}

#chapterContentPlanLesson {
    width: 100vw;
}

.lessonItemRatingBullet {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 10px;
    display: inline-block;
    vertical-align: middle;
}

.lessonItemRatingBullet + .lessonItemInner {
    margin-left: 0;
    width: calc(~"100% - 57px");
    display: inline-block;
    vertical-align: middle;
}

.selectedItem {
    color: @inactive-color !important;
}

.lessonItem {
    box-sizing: border-box;
    width: 100%;
}

.chapterTitle + .lessonItem .lessonItemInner {
    border-top: none;
}

#lessonWithExecuteLesson {
    font-weight: @font-weight-light;
    padding-left: 7px;
}

.methodicalHintExecuteLessonIcon {
    color: white;
    align-self: flex-start;
    padding-right: @default-margin-value;
    padding-top: @default-padding-value;
}

.methodicalHintExecuteLessonIcon i {
    font-size: 42px;
}

.methodicalHintExecuteLessonContent h3 {
    margin-top: 0;
}

.methodicalHintExecuteLessonContent {
    padding-top: @default-padding-value;
    padding-bottom: @default-margin-value;
    width: calc(~"100% - 60px");
}

#methodicalHintExecuteLesson {
    margin-left: @default-padding-value;
    margin-right: @default-padding-value;
}

.methodicalHintExecuteLesson-inner {
    margin-top: @default-padding-value;
    margin-bottom: @default-padding-value;
    background: @white-transparency;
}

#chapterTitleExecuteLesson {
    font-size: @large-font-size;
    font-weight: @font-weight-light;
}

.lessonItemInner {
    border-top: 1px solid @gray-color;
    padding: @default-margin-value 0;
    box-sizing: border-box;
    margin: 0 @default-margin-value;
}

.lessonItem:first-child .lessonItemInner {
    border-top: none;
}

.planLessonLegend > div {
    width: calc(~"50% - 35px");
    padding: 7px 15px;
    color: @title-color;
}

.planLessonContainer .km-listview {
    background: @white-transparency;
}

.planLessonContainer .km-listview .km-group-container:first-child .chapterTitle,
.planLessonContainer .km-scroll-header .chapterTitle {
    padding-top: 3px;
}

.planLessonContainer .km-list > li {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-color: @gray-color;
}

.planLessonContainer .km-list {
    padding-left: 15px;
    padding-right: 15px;
}

.planLessonContainer .km-group-title {
    background: transparent;
}

#plannedLessonsPlanLesson .lessonItem {
    position: relative;
}

#plannedLessonsPlanLesson > .lessonItem:last-of-type {
    border-radius: 0 0 5px 5px;
}

#plannedLessonsPlanLesson > .lessonItem:last-child {
    border-radius: 0 0 5px 5px;
}

.planLessonContainers {
    align-items: flex-start;
}

.planLessonContainer {
    position: relative;
    width: calc(~"50% - 35px");
}

.planLessonContainerRight .lessonItemInner:after {
    content: "\e985";
    font-family: "caviar" !important;
    color: @orphy-green;
    position: absolute;
    right: 17px;
    top: 20px;
}

.planLessonContainerRight .lessonItemInner {
    padding-right: 25px;
}

.km-content.km-stretched-view {
    -webkit-overflow-scrolling: touch;
}

.scrollElement {
    /*position: fixed;*/
    /*max-height: calc(~"100vh - 120px");*/
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    overflow-x: hidden;
}

.lessonItemSelected {
    background-color: #ccecef;
}

.lessonItemAdded {
    color: @gray-color;
}

.endLessonOverview {
    justify-content: space-around;
}

#subscriptionAvailableEndLesson {
    margin-top: @high-margin-value;
}

.baseSettingsSubscriptions {
    padding-top: 30px;
}

.billSettingsContainer {
    padding-top: 30px;
}

.endLessonNumberField {
    width: 55px;
    border: none;
}

.chapterTitle {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    font-weight: @font-weight-bold;
    font-size: @small-font-size;
}

#allLessonsPlanLesson,
.planLesson .chapterTitleWrapper,
#plannedLessonsPlanLesson {
    width: calc(~"50% - 35px");
}

#allLessonsPlanLesson,
.planLesson .chapterTitleWrapper .chapterTitle {
    border-radius: 5px 5px 0 0;
}

#allLessonsPlanLesson .lessonItem:first-child {
    border-top: 1px solid @gray-color;
}

#plannedLessonsPlanLesson {
    margin-left: auto;
    margin-top: -39px;
    border-radius: 5px 5px 0 0;
}

.planLessonArrow {
    align-self: flex-start;
    width: 70px;
    text-align: center;
    margin-top: 75px;
}

.planLessonArrow i {
    font-size: 52px;
    color: white;
}

#allLessonsPlanLesson,
#selectedLessonsPlanLesson,
#plannedLessonsPlanLesson .lessonItem {
    background: @white-transparency;
}

#allLessonsPlanLesson .chapterTitle,
.planLesson .chapterTitle {
    font-size: @small-font-size;
    font-weight: @font-weight-bold;
    background: transparent;
    color: black;
    padding-bottom: 5px;
    padding-top: 15px;
    padding-left: @default-margin-value;
    padding-right: @default-margin-value;
    border-bottom: 1px solid @gray-color;
}

.planLesson .chapterTitle {
    background: @white-transparency;
    border-bottom: none;
}

#headerLessonCountCompletedDrivingLessons {
    padding-right: 7px;
}

.lessonCountCompletedDrivingLessonsText {
    font-size: 16px;
    line-height: 18px;
}

.listFooterRow .tableCell {
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid white;
}

.listFooterRow .tableRow .tableCell:first-child {
    padding-left: 0;
}

.listFooterRow .tableRow .tableCell:last-child {
    padding-right: 0;
}

.monthSubHeading {
    height: 60px;
}

#dateTitleCompletedLessons {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    padding-top: 15px;
}

#lessonCountTextStudentDetail {
    font-size: 16px;
    line-height: 18px;
}

.studentDetailsLFASection {
    margin-top: 25px;
}

.lfaExpireDate {
    white-space: normal;
}

#lessonCountStudentDetail {
    padding-right: 6px;
}

.personDetailHeaderStudentDetail {
    padding-left: @default-padding-value;
    padding-right: 55px;
}

.personDetailHeader {
    justify-content: center;
    padding-bottom: 25px;
    padding-top: 25px;
}

.personDetailHeader .largeNumber {
    margin-right: 7px;
    margin-left: 7px;
}

.personDetailAvatar {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    overflow: visible;
}

.settleSelected .btn {
    white-space: normal;
    min-width: initial;
}

#studentAvatarEndLesson {
    padding-right: 7px;
    white-space: nowrap;
}

#avatarTitleCompletedDrivingLessons {
    margin-right: @high-margin-value;
}

.lessonCountEndLessonWrapper {
    white-space: nowrap;
}

.endLessonCount .largeNumber {
    font-size: 50px;
    padding-left: 5px;
}

.endLessonCountText {
    line-height: 21px;
}

#noSubscriptionAvailableEndLesson,
#balanceTooSmallEducationCard {
    background: @white-transparency;
    padding: 35px 0 50px 0;
}

.noNatchingProducts {
    margin-bottom: 7px;
}

.noSubscriptionAvailableEndLessonContent {
    padding-left: 9%;
    padding-right: 9%;
    box-sizing: border-box;
}

.noSubscriptionAvailableEndLessonQuestion {
    margin-bottom: 30px;
}

.noSubscriptionAvailableEndLessonPaymentRow .editingInputFieldContainer {
    width: 70px;
    padding: 5px 10px;
    margin: 0 5px;
}

.noSubscriptionAvailableEndLessonPaymentRow label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(~"100% - 13px");
}

.noSubscriptionAvailableEndLessonPaymentRowInputs {
    width: 100%;
}

.warning-symbol {
    font-size: 27px;
    margin-right: 7px;
}

.lfaPhoto {
    /*align-self: flex-end;*/
    padding: 15px;
    border: 1px dotted @orphy-green;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.lfaPhoto .iconOnly i {
    margin: 0 auto;
    display: block;
}

.lfaPhoto.imageAdded {
    border: none;
    padding: 2px;
}

.km-widget #detailsScrollerStudentDetail .studentDetailLFASection {
    align-items: stretch;
    padding-right: 30px;
}

.lfaPhoto .btnPopup {
    margin: 0;
    padding: 0;
}

.lfaPhoto img {
    max-width: 90px;
    max-height: 120px;
    position: relative;
}

.imageAdded #LFAPhotoStudentDetail:after,
.imageAdded #LFAPhotoRegisterStudent:after,
#companyLogoContainer.imageAdded:after {
    content: "\eae0";
    font-family: "caviar" !important;
    position: absolute;
    background: rgba(255, 255, 255, 1);
    bottom: 13px;
    right: -5px;
    border: 1px solid @orphy-green;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @orphy-green;
    font-size: 20px;
    pointer-events: none;
}

#companyLogoContainer.imageAdded:after {
    right: calc(~"50% - 17px");
    bottom: -15px;
}

#LFAPhotoStudentDetail,
#LFAPhotoRegisterStudent {
    position: relative;
}

#lfaPhotoContainerRegisterStudent.imageAdded {
    padding: 0;
}

#lfaPhotoContainerRegisterStudent {
    padding-top: 25px;
    padding-bottom: 5px;
    box-sizing: border-box;
    margin-left: 25px;
}

.registerStudentLFAThumb .inputFieldContainer {
    min-width: 350px;
}

#lfaPhotoContainerRegisterStudent img {
    max-width: initial;
}

.registerStudentLFAThumb img {
    max-height: 80px;
}

.registerPersonActionButtons {
    margin-top: 15px;
}

.registerPersonActionButtons .btn {
    font-size: 16px;
    padding: 0px 20px;
    border-radius: 50px;
    height: 48px;
}

.personDetailNoAvatar {
    height: 52px;
    width: 52px;
    min-width: 52px;
    min-height: 52px;
    font-size: 24px;
    font-weight: @font-weight-bold;
    color: white;
    background-color: #a4adbc;
    border-radius: 50%;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}

.personDetailInfoMeetingPoint {
    font-weight: @font-weight-light;
}

.personDetailInfo {
    font-size: @medium-font-size;
}

.personDetailSMSButton {
    height: 42px;
    width: 42px;
}

.k-list-scroller {
    height: 100% !important;
}

/* Hide default HTML checkbox */
.subscriptionsStateTitle .k-checkbox-label {
    margin-bottom: 0;
}

.switch input {
    display: none;
}

.switcher-wrapper {
    white-space: nowrap;
}

.switcher-wrapper span,
.switcher-wrapper label {
    display: inline-block;
    vertical-align: middle;
}

.allStudentsShowInactiveLegend {
    text-align: right;
    font-size: @small-font-size;
    margin-right: 7px;
    line-height: 14px;
    min-width: 100px;
}

#controlHeaderAllStudents {
    margin-top: 25px;
}

.allPersonsList li .tableCell:first-child {
    font-size: @medium-font-size;
}

.allPersonsNoPersons .largeNumber {
    font-size: 65px;
    letter-spacing: -0.05em;
    font-weight: 300;
}

.allPersonsNoPersons .step2 {
    margin-left: auto;
}

.allPersonsNoPersons img {
    margin-right: 30px;
}

.allPersonsNoPersons .noPersonsText + img {
    margin-left: 30px;
    margin-right: 0;
}

.iconCell .iconOnly {
    vertical-align: middle;
}

.noPersonsText .largeNumber {
    margin-right: 10px;
}

.noPersonsText {
    align-items: flex-start;
}

.noPersonsInscructions {
    font-weight: 300;
}

.k-checkbox-label {
    line-height: 1.2;
}

.k-checkbox-label:before {
    border: none;
    /*margin-top: 5px;*/
}

.tableCell .k-checkbox-label:before {
    margin-top: 0;
}

.tableCell .k-checkbox-label {
    height: 18px;
}

.k-checkbox:checked + .k-checkbox-label:before {
    font-family: "caviar" !important;
    content: "\e9b1";
    font-weight: bold;
}

input.k-checkbox {
    margin-bottom: 0;
}

.k-checkbox:checked + .k-checkbox-label:before {
    color: #000 !important;
    border: none !important;
}

.k-checkbox:focus + .k-checkbox-label:before {
    border: none !important;
    box-shadow: none !important;
}

.k-checkbox-label:active:before {
    border: none !important;
    box-shadow: none !important;
}

.k-checkbox:checked + .k-checkbox-label:active:before {
    border-color: #000 !important;
}

/* Kendo Switcher*/
.km-flat .km-switch-handle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #c1dce5;
    border: none;
    box-shadow: none;
    margin-top: 4px;
    margin-left: 4px;
}

.km-flat .km-switch-off .km-switch-handle {
    box-shadow: none;
    color: rgba(0, 0, 0, 1);
}

.km-flat .km-switch-handle.km-state-active {
    background: @orphy-green-hover;
}

.km-flat .km-switch-wrapper {
    border: none;
    border-radius: 34px;
    background: transparent;
    border-image-width: 0;
    background: @white-transparency;
    transition: all ease @default-transition-duration;
}

.km-switch-on .km-switch-handle {
    background: @orphy-green;
}

.km-root .km-flat .km-switch {
    background-image: none;
    background-color: transparent;
    border: none;
    width: 60px;
    min-width: 60px;
    height: 34px;
}

.km-flat .km-switch-container {
    border: none;
    padding: 0;
}

.km-flat .km-switch-background {
    color: rgba(0, 0, 0, 0);
    background: none transparent;
}

/* Kendo Switcher End*/
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @white-transparency;
    -webkit-transition: 0.1s;
    transition: 0.1s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #c1dce5;
    -webkit-transition: 0.1s;
    transition: 0.1s;
}

input:focus + .slider {
    box-shadow: 0 0 1px @orphy-green;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: @orphy-green;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    -moz-transform: translate3d(0, 0, 0) filter blur(2px);
    -ms-transform: translate3d(0, 0, 0) filter blur(2px);
    -o-transform: translate3d(0, 0, 0) filter blur(2px);
    -webkit-transform: translate3d(0, 0, 0) filter blur(2px);
    transform: translate3d(0, 0, 0) filter blur(2px);
}

.slider.round:before {
    border-radius: 50%;
}

.inactivePerson {
    color: #869399;
}

.align-center {
    margin: 0 auto;
    width: 50%;
}

.dropdown {
    position: relative;
}

.dropdown-content {
    z-index: 1000;
    display: none;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    overflow: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    min-width: 60px;
    padding: 15px 25px;
    border-radius: 18px;
}

.dropdown-content-note {
    z-index: 1000;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    min-width: 250px;
}

.dropDownItem.dropDownSelected {
    color: black;
}

.btnDropdown {
    background: none;
    border: none;
    padding-left: 0;
}

.btnDropdown i {
    pointer-events: none;
}

.dropdown-content a {
    color: black;
    padding: 10px 3px 10px 3px;
    text-decoration: none;
    display: block;
}

.dropDownItem {
    color: @orphy-green;
    padding: 13px 0;
}

#scrollableDropdownContent {
    padding: 0;
}

.dropdown-content div:not(.km-scroll-header):not(.km-scroll-container):not(.km-touch-scroll-bar):not(.km-vertical-scrollbar) {
    text-decoration: none;
}

.dropdown-content div:not(.km-scroll-header):not(.km-scroll-container):not(.km-touch-scroll-bar):not(.km-vertical-scrollbar):last-child {
    border: none;
}

.dropdownTitleExecuteLesson {
    font-size: @small-font-size;
    border-bottom: 1px solid black;
    border-bottom-width: thin;
    font-weight: @font-weight-bold;
    padding: 10px 0;
}

#stepExectueLesson {
    white-space: nowrap;
    padding-top: @default-margin-value;
    padding-bottom: @default-margin-value;
}

#stepExectueLesson .dropdown-content {
    white-space: normal;
}

#stepExectueLesson .dropDownItem {
    padding: 8px 0;
}

.dropDownListExecuteLesson {
    max-height: 158px;
    overflow-y: scroll;
    position: relative;
}

.dropDownListExecuteLesson .dropDownItem {
    padding: 8px 0;
    font-size: @small-font-size;
}

.detailHeaderExecuteLessonWrapper {
    background: @white-transparency;
    border-bottom: 1px solid rgba(150, 150, 150, 0.15);
    padding-top: @default-margin-value;
    position: relative;
}

.detailHeaderExecuteLesson button,
.detailHeaderExecuteLesson .dropdown {
    margin: 0 5px;
}

.executeLessonNavigationContainer {
    align-self: flex-start;
}

.executeLessonTabs {
    justify-content: center;
}

.detailHeaderExecuteLesson .dropdown {
    margin-left: auto;
    align-self: flex-start;
}

.detailHeaderContactInfos {
    padding: 10px 0;
    margin: 0 @default-padding-value 0 @default-padding-value;
}

.ratingTitleExecuteLesson {
    padding-top: 15px;
    font-weight: @font-weight-bold;
    font-size: @small-font-size;
}

.executeLessonShareButtons {
    justify-content: flex-end;
    background: @white-transparency;
    padding-top: 15px;
}

.contentExecuteLessonInner {
    background: @white-transparency;
    padding-bottom: 20px;
    padding-left: @default-padding-value;
    padding-right: @default-padding-value;
    padding-top: 25px;
}

.ratingFormWrapperExecuteLesson {
    padding: 25px 0;
    border-bottom: 1px solid white;
}

.border-bottom-row {
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: white;
}

#buttonHeaderExecuteLesson {
    height: 40px;
    background: none;
}

.studentDetailTitle {
    font-size: @small-font-size;
    line-height: 1;
}

#meetingPointNextLessonStudentDetail .overviewInfoContent {
    white-space: normal;
}

.overviewInfoHighlight {
    padding-right: 10px;
    align-self: flex-start;
}

.educationRatingStudentDetailButtonBar {
    width: 100%;
}

.educationRatingStudentDetailButtonBar > .btn {
    margin: 5px 0;
}

.educationRatingStudentDetailButtonBar > .btn:first-child {
    margin-right: 7px;
}

.overviewInfoHighlightLegend {
    font-size: 16px;
}

.overviewInfoHighlight .largeNumber {
    font-size: 46px;
    margin-right: 4px;
}

#LFAandExamStudentDetail .studentDetailTitle {
    padding-top: 30px;
    padding-bottom: 18px;
}

.StudentDetailContactInfo {
    border-right: 1px solid white;
}

.endLessPopupContent {
    margin-left: 30px;
    margin-right: 30px;
}

.popup {
    z-index: 10000000;
    display: none;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.09);
    min-width: 160px;
    padding: 0px 0 5px 0;
    background-clip: padding-box;
    border-radius: 15px;
}

.showPopup {
    display: block;
}

.popUpInnerButton {
    white-space: nowrap;
    padding: 10px 0;
    line-height: 1.2;
}

.popUpInnerButton button {
    color: @orphy-green;
    background: transparent;
    border: none;
}

.popUpInnerButton button,
.popUpInnerButton i {
    display: inline-block;
    vertical-align: middle;
    padding: 0 4px;
    line-height: 1.2;
}

.popUpInnerButton i {
    font-size: @large-icon-font-size;
}

.popUpInnerButton .btnText {
    padding-left: 7px;
}

.selectableText {
    user-select: text;
}

.popupTitle {
    height: auto;
    color: black;
    padding: 15px;
}

.popupContent {
    padding: 7px 25px 0 25px;
    position: relative;
    min-height: 180px;
    overflow: auto;
    box-sizing: border-box;
}

.popupFooter {
    padding: 10px 13px 5px 25px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.popupFooter:after {
    clear: both;
    content: "";
    display: table;
}

.popupIconBar {
    top: 0;
    padding-right: 20px;
}

.dropdown-content .popupIconBar {
    position: absolute;
    right: 0;
    top: -5px;
}

.centerText {
    text-align: center;
}

.leftText {
    text-align: left;
}

.rightText {
    text-align: right;
}

.redText {
    color: @red-color;
}

.whiteText {
    color: white;
}

.greenText {
    color: @orphy-green;
}

.grayText {
    color: @title-color;
}

.fontBold {
    font-weight: @font-weight-bold;
}

.fontLight {
    font-weight: @font-weight-light;
}

.ratingSmileyRow {
    white-space: nowrap;
}

.ratingSmileyRow .ratingCircle:first-child {
    margin-left: 0;
    padding-left: 0;
}

.ratingHistoryPagination {
    font-size: @small-font-size;
    vertical-align: top;
    padding-right: 7px;
    color: @inactive-color;
}

.currentLessonRated {
    color: black;
    font-weight: @font-weight-bold;
}

.ratingHistoryDots {
    white-space: normal;
    padding-right: 10px;
}

.ratingHistoryDotsWrapper {
    padding-top: 2px;
}

.ratingHistoryRow .default-inline-block {
    vertical-align: top;
}

.ratingHistoryDot {
    height: 12px;
    width: 12px;
    background: @white-transparency;
    border-radius: 50%;
    margin: 2px 2px 2px 2px;
    float: left;
}

.ratingHistoryRow {
    white-space: nowrap;
    margin-top: 5px;
}

.ratingCircle {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    margin: 3px;
    padding: 0 7px;
    position: relative;
}

.ratingCircle i {
    font-size: 45px;
    color: @inactive-color;
}

.number-confirm {
    position: absolute;
    background: @blue-color;
    color: white;
    z-index: 1;
    right: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    border-radius: 50%;
    padding: 2px;
}

.number-confirm i {
    font-size: 11px;
}

.elementToFadeInAndOut {
    -webkit-animation: fadeinout 1s linear forwards;
    animation: fadeinout 1s linear forwards;
}

/* Cards Styling */
.card {
    background: @white-transparency;
    overflow: hidden;
    padding: 25px 18px 18px 18px;
    font-size: 14px;
    position: relative;
}

#cardsRight div:first-child .card,
#cardsRightPaperworkDashboard div:first-child .card {
    margin-bottom: 20px;
}

#cardsRight .card {
    height: 290px;
}

#cardCalendar {
    background: @white-transparency;
    overflow: hidden;
    position: relative;
    height: 100%;
}

.dashboardCardWrapper {
    overflow: hidden;
}

.cardScroller {
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    padding-bottom: 50px;
    height: 100%;
}

.cardOverflown:after {
    position: absolute;
    width: 100%;
    height: 60px;
    content: " ";
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    right: 0;
    pointer-events: none;
    background: -moz-linear-gradient(top, rgba(236, 236, 236, 0) 0%, #ececec 100%);
    background: -webkit-linear-gradient(top, rgba(236, 236, 236, 0) 0%, #ececec 100%);
    background: linear-gradient(to bottom, rgba(236, 236, 236, 0) 0%, #ececec 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ececec', endColorstr='#ececec', GradientType=0);
}

.cardBillsStudentInfo {
    overflow: hidden;
}

.cardBillsStudentStatus {
    white-space: nowrap;
}

#cardCalendar .card {
    background: transparent;
    height: 100%;
    overflow: hidden;
}

#cardTimeTrackings .card {
    height: calc(~"100vh - 216px");
    overflow: hidden;
}

.card .personDetailNoAvatar {
    height: 40px;
    width: 40px;
    min-width: initial;
    min-height: initial;
    font-size: 14px;
}

.cardHeaderArrow {
    background: @white-transparency;
    display: inline-block;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    top: 0;
    padding: 7px;
}

.cardHeaderArrow i {
    font-size: 27px;
    color: #888888;
    box-sizing: border-box;
}

.card .contentTitle {
    border-bottom: 1px solid white;
    line-height: 1.6;
    padding-bottom: 3px;
}

.card .titleWrapper + .contentTitle {
    margin-top: 10px;
}

.cards #cardsLeft,
.cards #cardsLeftPaperworkDashboard {
    padding-right: 10px;
}

.cards #cardsRight,
.cards #cardsRightPaperworkDashboard {
    padding-left: 10px;
}

.card .content {
    padding-left: 0;
    margin-top: 10px;
    list-style: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.card .titleWrapper {
    margin-bottom: 25px;
}

.cardNumber {
    font-size: @extra-large-font-size;
    font-weight: @font-weight-light;
    color: @red-color;
}

.cardText {
    font-size: 14px;
    padding-left: 7px;
    line-height: 18px;
}

.card .additional {
    border-left: 1px solid white;
    padding-left: 15px;
    margin-left: 15px;
}

.card .title {
    font-size: @default-font-size;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card .legend {
    color: #888888;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card .location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card .tomorrow {
    color: #888888;
}

.card .cardEntry {
    margin-bottom: 15px;
}

.card .content:last-of-type .cardEntry:last-child {
    margin-bottom: 0;
}

.card .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.card .avatar,
.card .cardInformationWrapper {
    float: left;
}

.card .cardInformationWrapper {
    width: calc(~"100% - 55px");
}

#cardCalendar .card .cardInformationWrapper {
    width: 100%;
}

/* Cards Styling End*/
/* ICON Font Caviar */
.k-icon {
    font-family: "caviar" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 16px;
    /* better font rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.subscriptionInfoColumn {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.subscriptionBillInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 100px;
}

.subscriptionBillInfo span {
    width: 100%;
}

/* Orphy Drive Subscriptions End */
@-webkit-keyframes fadeinout {
    0%,
    100% {
        opacity: 0;
    }

    45%,
    60% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0%,
    100% {
        opacity: 0;
    }

    45%,
    60% {
        opacity: 1;
    }
}

/* Checkbox and Radiobuttons*/
/*input[type=checkbox]:not(old) {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0;
}*/
input[type="radio"]:not(old) {
    width: 1.2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0;
    position: absolute;
}

//input[type=checkbox]:not(old) + label,
input[type="radio"]:not(old) + label {
    display: inline-block;
    margin-left: -1.6em;
    line-height: 36px;
    height: 36px;
    position: relative;
}

/*input[type=checkbox]:not(old) + label > span {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin: 0.25em 0.5em 6px 0.25em;
    border: none;
    border-radius: 2px;
    background: white;
    vertical-align: bottom;
}*/
/*input[type=checkbox].grayCheckbox:not(old) + label > span {
    background: #ebebeb;
}*/
input[type="radio"]:not(old) + label > span {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0.15em 0.5em 0.45em 0.25em;
    border: none;
    border-radius: 50%;
    background: white;
    vertical-align: bottom;
    transition: all ease 0.2s;
    pointer-events: none;
}

input[type="radio"]:not(old) + label > span > span {
    pointer-events: none;
}

input[type="radio"].outlineRadio:not(old) + label > span {
    border: 2px solid @gray-color;
}

input[type="radio"]:not(old):checked + label > span {
    border: 5px solid @orphy-green;
}

/*input[type="checkbox"]:not(old):checked + label > span,
input[type="radio"]:not(old):checked + label > span {
}
input[type=checkbox]:not(old):checked + label > span:before {
    content: '\e9b1';
    font-family: "caviar" !important;
    display: block;
    width: 1em;
    color: black;
    font-size: 23px;
    line-height: 1;
    text-align: center;
    text-shadow: none;
    font-weight: bold;
}*/
.isOffline .onlyOnline {
    visibility: hidden;
}

.isOffline .onlyOnlineInteraction {
    pointer-events: none !important;
}

/*input[type=radio]:not(old):checked + label > span > span {
    display: block;
    width: 0.5em;
    height: 0.5em;
    margin: 0.2em;
    border: 0.0625em solid @orphy-green;
    border-radius: 0.125em;
    background: @orphy-green;
}*/
/* Checkbox and Radiobuttons End*/
#scroller-view .scroller-content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
}

.confirmContent > .largeTitle {
    font-size: 85px;
    line-height: 1;
}

.confirmContent {
    z-index: 4000001;
    position: relative;
}

.default-flex.confirmAvatar {
    justify-content: center;
    margin-bottom: 20%;
}

.confirmAvatar .largeTitle {
    margin-bottom: 0;
}

.confirmAvatar .personDetailNoAvatar {
    margin-right: 15px;
}

/* Exam Confirm Screen End */
/* Drawing Screen */
.drawing-toolbar {
    background: @orphy-green;
    align-items: stretch;
}

.drawing-toolbar .k-widget.k-slider.k-slider-horizontal {
    width: 100% !important;
    height: 40px;
    margin-top: -14px;
}

#drawingMasterElement .default-flex {
    justify-content: flex-start;
}

.drawing-toolbar-button {
    background: none transparent;
    color: white;
    border: none;
    font-size: 14px;
    border-right: 1px solid white;
    padding: 5px 15px;
    white-space: nowrap;
    text-align: center;
}

.drawing-toolbar-button:not(:nth-child(1)) {
    width: 70px;
}

.drawing-toolbar-button:last-child {
    border-right: none;
}

.drawing-toolbar-button i {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
}

.drawing-toolbar-button.btnActive {
    background: @orphy-green-hover;
}

.drawing-stroke-button {
    white-space: normal;
}

.drawing-stroke-button label {
    margin: 0;
    font-weight: @font-weight-normal;
}

.drawing-color-button {
    padding-top: 12px;
}

/* Drawing Screen End */

.productsPriceInput .k-numeric-wrap.k-state-default.k-expand-padding::before {
    padding: 0;
    content: none;
}

.productsPriceInput .k-formatted-value.inputField.k-input {
    background-color: white;
}

.productsPriceInput .k-numeric-wrap.k-state-default.k-expand-padding.k-state-hover {
    background-color: white;
}

.productsPriceInput .k-numeric-wrap.k-state-default.k-expand-padding {
    background-color: white;
    padding-top: 3px;
    padding-bottom: 2px;
}

.productsPriceInput .k-numeric-wrap.k-state-default.k-expand-padding.k-state-focused {
    border: 1px solid #279fad;
}

.productsPriceInput .k-autocomplete.k-dropdown-wrap.k-numeric-wrap.k-picker-wrap.k-textbox {
    border: none;
}

.produtcsPriceInput .k-autocomplete,
.k-dropdown-wrap,
.k-numeric-wrap,
.k-picker-wrap,
.k-textbox {
    border: unset;
}

#enterLessons > .productsPriceInput .k-formatted-value.inputField.k-input {
    background: transparent;
}

#enterLessons .productsPriceInput .k-numeric-wrap.k-state-default.k-expand-padding {
    background: transparent;
}

#enterLessons .productsPriceInput .k-formatted-value.inputField.k-input {
    background: transparent;
}

#enterLessons .productsPriceInput .k-numeric-wrap.k-state-default.k-expand-padding.k-state-focused {
    border: none;
}

.myDriveGalleryPicture iframe + em {
    display: block;
}

.wrappedMyDriveGalleryPicture + em {
    display: block;
}

.gridTable.table.tableFixed.subTableNew {
    margin-left: 0;
}

.gridTable.table.tableFixed.subTableNew .defaultButtonContainer {
    margin-left: 34px;
}

.gridTable.table.tableFixed.subTableEdit {
    margin-left: 0;
}

.gridTable.table.tableFixed.subTableEdit .defaultButtonContainer {
    margin-left: 34px;
}

@media screen and (max-width: 480px) {
    .gridTable.subTableEdit {
        width: 100%;
    }

    .gridTable.table.tableFixed.subTableEdit .defaultButtonContainer {
        margin-left: 0px;
    }

    .gridTable.table.tableFixed.subTableNew .defaultButtonContainer {
        margin-left: 25px;
    }
}

.k-switch-label-on {
    display: none !important;
}

.k-switch-label-off {
    display: none !important;
}

.k-switch.k-widget.checkboxSwitch.calendarSwitch.k-switch-off {
    background: transparent;
    border: none;
}

.k-switch.k-widget.checkboxSwitch.calendarSwitch.k-switch-on {
    background: transparent;
    border: none;
}

.k-icon.k-i-arrow-end-right,
.k-icon.k-i-arrow-60-right {
    transform: rotate(90deg);
}

.k-icon.k-i-arrow-60-left,
.k-icon.k-i-arrow-end-left {
    transform: rotate(270deg);
}

#allPersonListView {
    height: 100%;
    display: flex;
    background: transparent;
    border: none;
}

#allPersonListView .k-listview-content {
    width: 100%;
}

.allPersonTemplate {
    display: flex;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid white;
    height: 42px;
}

.allPersonTemplate .allpersonName {
    min-width: 50%;
    max-width: 50%;
    line-height: 42px;
}

.allPersonTemplate .checklistName {
    min-width: 29%;
    line-height: 42px;
}

.allPersonTemplate .iconCell {
    margin-left: auto;
}

.k-loading-mask {
    display: none;
}

#checklistViewEducationCardListView .km-group-container:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

#checklistViewEducationCardListView .headerTemplateEducationCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#checklistViewEducationCardListView .tableCell.educationPaperNoteCell.cell2.noPadding {
    padding: 0;
}

.billingDatePicker {
    display: flex;
}

#easyAccounting .isPaid {
    color: green;
}

#easyAccounting .notPaid {
    color: red;
}

.easyAccountingRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
}

div[data-template="easyAccountingPD"] > div {
    height: 70px;
}

div[data-template="easyAccountingPD"] > div:last-child > div {
    border-bottom: none;
}

.easyPaymentHeader {
    padding-bottom: 10px;
}

.workingHourWrapper {
    display: flex;
    flex-direction: row;
}

.workingHourItem {
    display: flex;
    flex-direction: row;
}

.workingHourItem:nth-child(2) {
    padding-left: 30px;
}

.tooMuchHours {
    color: #ff5556;
}

.workingHours .titleWrapper.default-inline-flex {
    margin-bottom: 10px;
}

.workingHourCard {
    margin-bottom: 20px;
}

.inputField.k-widget.k-editor.k-editor-inline.k-state-active {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.inputField.k-widget.k-editor.k-editor-inline {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.lfaImage {
    padding-top: 5px;
}

.finalAccountButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}
.flexMiddle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
}

.hasContent {
    color: #279fad;
}

.educationSwitch .studentDetailTitle {
    .studentDetailTitle;
    color: black;
    margin: 0;
}
.inputFieldContainer.educationSwitch {
    background: transparent;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: auto;
}

.inputFieldContainer.educationSwitch .k-dropdown-wrap .k-input {
    font-size: inherit;
    color: initial;
    margin: 0;
}

.inputFieldContainer.educationSwitch .k-dropdown-wrap .k-select {
    line-height: initial;
}

.educationSwitch .k-dropdown .k-i-arrow-60-down {
    margin-top: -6px;
}

.firstTimeButton {
    width: auto;
}
#testScrollerMainView > ul li {
    display: flex;
    justify-content: center;
    align-items: center;
}

#checklistViewEducationCardListView li ul li:has(div.wasInPlannedLesson) {
    background-color: lightblue;
}
.personDetailHeaderRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.personDetailHeaderRow .educationSwitch {
    margin: 0;
}
.personDetailHeaderRow .phoneButtons {
    display: flex;
}
.personDetailHeaderRow .phoneButtons a:first-child {
    margin-right: 10px;
}
.personDetailHeaderRowDoubleRow {
    display: flex;
}

.personDetailHeaderRowDoubleRow .lessonBox {
    padding-left: 7px;
}

.personDetailHeaderRowDoubleRow .phoneBox {
    padding-right: 7px;
    border-right: 1px white solid;
}

.personDetailHeaderRowDoubleRow .sellSubscriptions {
    margin-left: 10px;
}

.educationCardButtons div:nth-child(2) {
    margin-top: 5px;
}

.educationCardButtons,
.planLessonButton {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.lessonActionButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.align-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.lessonNotes {
    background-color: #fffffff5;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.dangerButton {
    background-color: rgba(255, 0, 0, 0.61);
    border: none;
}

.largeCard {
    height: calc(100vh - @header-height - @footer-height - 6vh);
}

.largeCard .dashboardCardWrapper {
    overflow-y: auto;
    height: calc(100vh - @header-height - @footer-height - 20vh);
}

.smallCard {
    height: calc((100vh - @header-height - @footer-height) / 2 - 4vh);
}

.smallCard:first-child {
    margin-bottom: 2vh;
}

.smallCard .cardScroller {
    height: calc((100vh - @header-height - @footer-height) / 2 - 17vh);
    padding-bottom: 0px;
}

.additionalEmail .inputFieldContainer {
    height: auto;
}

.footer {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-around;
    min-height: @footer-height;
    height: @footer-height;
    max-height: @footer-height;
    align-items: center;
    background-color: #052136;
}

.footer-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-active-button {
    color: @orphy-green;
}

.footer-button i {
    font-size: 30px;
}

.footer-button i.custom-icon-student-hat {
    font-size: 45px;
    // hack because icon does not have the same size
    margin-top: -5px;
}

.footer-button i.custom-icon-student-hat + span {
    // hack because icon does not have the same size
    margin-top: -5px;
}

.footer-button-text {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alarm-symbol {
    content: "!";
    font-weight: bold;
    background: @red-color;
    color: white;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
    position: absolute;
}

.new-subscription:after {
    .alarm-symbol;
    right: 20px;
    top: 0;
}

.settings-notification.new-subscription:after {
    right: -20px;
}

.new-subscription {
    position: relative;
}
